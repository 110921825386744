import { Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { PlotReportFactLabel, R1FactType } from '@/api/rest/resources/types/fact';
import { CarbonStorageBGHistoricalChart } from '@/components/ChartsV1_2';
import { ChartHeading } from '@/components/ChartsV1_2/components/ChartHeading';
import { GraphFact } from '@/components/ChartsV1_2/types';
import { kgToTonneFormatter } from '@/components/ChartsV1_2/utils';
import { useNCCardContext } from '@/components/NCCardV1_2/NCCard';
import { useBenchmarksForPlot } from '@/pages/shared/hooks/useBenchmarkForFact';
import { usePlot } from '@/pages/shared/hooks/usePlot';
import { usePlotReportForPlot } from '@/pages/shared/hooks/usePlotReportForPlot';

import { NoPlotData } from '../NoPlotData';

export const CarbonGraphTile = () => {
  const { t } = useTranslation();
  const plot = usePlot().data;
  const { analysisType } = useNCCardContext();

  const { getFact } = usePlotReportForPlot({ plotId: plot.id });

  const carbonBgHistorical = getFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.r1_soil_carbon_historic_total_graph
      : R1FactType.r1_soil_carbon_historic_per_ha_graph,
  );
  const carbonbgHistoricalData = carbonBgHistorical?.value;

  const carbonBgLowBenchmark = getFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.permanent_soil_carbon_total_benchmark_graph
      : R1FactType.permanent_soil_carbon_per_ha_benchmark_graph,
    [{ name: 'benchmark', value: 'low' }] as PlotReportFactLabel[],
  );
  const cbgLowBenchmarkData = (carbonBgLowBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_low` };
  });
  const carbonBgModerateBenchmark = getFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.permanent_soil_carbon_total_benchmark_graph
      : R1FactType.permanent_soil_carbon_per_ha_benchmark_graph,
    [{ name: 'benchmark', value: 'moderate' }] as PlotReportFactLabel[],
  );
  const cbgModerateBenchmarkData = (carbonBgModerateBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_moderate` };
  });
  const carbonHighBenchmark = getFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.permanent_soil_carbon_total_benchmark_graph
      : R1FactType.permanent_soil_carbon_per_ha_benchmark_graph,
    [{ name: 'benchmark', value: 'high' }] as PlotReportFactLabel[],
  );
  const cbgHighBenchmarkData = (carbonHighBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_high` };
  });

  const cbgBenchmarks = useBenchmarksForPlot(
    analysisType === 'total'
      ? R1FactType.permanent_soil_carbon_total_benchmark_graph
      : R1FactType.permanent_soil_carbon_per_ha_benchmark_graph,
    null,
    kgToTonneFormatter,
  );

  const noChartData = !carbonbgHistoricalData?.length;
  if (noChartData) {
    return <NoPlotData />;
  }

  const chartData = [
    ...carbonbgHistoricalData,
    ...cbgLowBenchmarkData,
    ...cbgModerateBenchmarkData,
    ...cbgHighBenchmarkData,
  ];

  return (
    <Stack
      spacing={6}
      className='items-center justify-center p-6 lg:items-start lg:p-8 lg:pl-2'
      data-testid='carbon-graph-tile'
    >
      <ChartHeading
        heading={t('shared.ncaDetail.details.carbon.labels.historicalGraphHeadline')}
        popover={{
          // TODO: MVP-3284 Confirm if the popover copy is correct
          title: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.title'),
          body: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.body'),
        }}
      />
      <CarbonStorageBGHistoricalChart
        analysisType={analysisType}
        data={chartData}
        benchmarks={cbgBenchmarks}
        showTooltip={true}
        className='relative'
        data-testid='carbon-storage-bg-chart'
      />
    </Stack>
  );
};
