import { InfoPopoverProps } from '@landler/tw-component-library';
import React, { HTMLAttributes } from 'react';
import { cn } from 'tw-component-library/utils';

import { Fact } from '@/api/rest/resources/conservation';
import {
  Capsule,
  CapsuleContent,
  CapsuleContentProps,
  CapsuleIcon,
  CapsuleIconProps,
  CapsuleLabel,
} from '@/components';

import { useFactDisplay } from '../hooks/useFactDisplay';

export type FactCapsuleProps = {
  fact: Fact;
  label: React.ReactNode;
  signed?: ValueFromFactProps['signed'];
  trend?: ValueFromFactProps['trend'];
  infoPopoverProps?: InfoPopoverProps | null;
  capsuleIconProps?: CapsuleIconProps;
};

export const FactCapsule = ({ fact, label, trend, signed, infoPopoverProps, capsuleIconProps }: FactCapsuleProps) => {
  return (
    <Capsule
      label={<CapsuleLabel infoPopoverProps={infoPopoverProps}>{label}</CapsuleLabel>}
      content={<ValueFromFact fact={fact} trend={trend} signed={signed} />}
      thumbnail={<CapsuleIcon {...capsuleIconProps} />}
    />
  );
};

type ValueFromFactProps = HTMLAttributes<HTMLDivElement> & {
  fact?: Fact;
  trend?: CapsuleContentProps['trend'];
  signed?: boolean;
};

const ValueFromFact: React.FC<ValueFromFactProps> = ({ fact, trend, className, signed }) => {
  const { value, display } = useFactDisplay(fact, { signed });

  if (value == null) {
    return <CapsuleContent className={cn('text-text-disabled', className)}>-</CapsuleContent>;
  }

  return (
    <CapsuleContent className={className} trend={trend}>
      {display}
    </CapsuleContent>
  );
};
