import { useTranslation } from 'react-i18next';
import { Stack } from 'tw-component-library';

import { PlotReportFactLabel, R1FactType } from '@/api/rest/resources/types/fact';
import { BiodiversityZoneChart } from '@/components/ChartsV1_2';
import { ChartHeading } from '@/components/ChartsV1_2/components/ChartHeading';
import { GraphFact } from '@/components/ChartsV1_2/types';
import { useBenchmarksForProject } from '@/pages/shared/hooks/useBenchmarkForFact';
import { useNCData } from '@/pages/shared/hooks/useNCData';

import { NoProjectData } from '../../../../components/NoProjectData';

export const BiodiversityCardGraphTile = () => {
  const { t } = useTranslation();

  const biodiversityZonePercentHistoricGraph = useNCData<GraphFact[]>(
    R1FactType.r1_biodiversity_zone_percent_historic_graph,
  );
  const bpHistoricData = biodiversityZonePercentHistoricGraph?.value;

  // TODO: MVP-3284 centralize this benchmarking formatting to a hook?
  const bpLowBenchmark = useNCData<GraphFact[]>(R1FactType.biodiversity_buffer_zone_pct_benchmark_graph, undefined, [
    { name: 'benchmark', value: 'low' },
  ] as PlotReportFactLabel[]);
  const bpLowBenchmarkData = (bpLowBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_low` };
  });
  const bpModerateBenchmark = useNCData<GraphFact[]>(
    R1FactType.biodiversity_buffer_zone_pct_benchmark_graph,
    undefined,
    [{ name: 'benchmark', value: 'moderate' }] as PlotReportFactLabel[],
  );
  const bpModerateBenchmarkData = (bpModerateBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_moderate` };
  });
  const bpHighBenchmark = useNCData<GraphFact[]>(R1FactType.biodiversity_buffer_zone_pct_benchmark_graph, undefined, [
    { name: 'benchmark', value: 'high' },
  ] as PlotReportFactLabel[]);
  const bpHighBenchmarkData = (bpHighBenchmark?.value ?? []).map((fact) => {
    return { ...fact, name: `${fact.name}_high` };
  });

  const bpBenchmarks = useBenchmarksForProject(R1FactType.biodiversity_buffer_zone_pct_benchmark_graph);

  /**
   * We only null check for bpHistoricData since bpHistoricData is the primary source data of the graph
   * reference lines are displayed only when bpHistoricData is rendered
   */
  const noChartData = !bpHistoricData?.length;
  if (noChartData) {
    return <NoProjectData />;
  }

  const chartData = [...bpHistoricData, ...bpLowBenchmarkData, ...bpModerateBenchmarkData, ...bpHighBenchmarkData];

  return (
    <Stack center data-testid='biodiversity-graph-tile'>
      <Stack spacing={2.5} className='w-full items-center justify-center p-6 lg:items-start lg:p-8 lg:pl-2'>
        <ChartHeading
          heading={t('shared.ncaDetail.details.biodiversity.labels.historicalGraphHeadline')}
          popover={{
            title: t('shared.ncaDetail.details.biodiversity.historicalData.title'),
            body: t('shared.ncaDetail.details.biodiversity.historicalData.body'),
          }}
        />
        <BiodiversityZoneChart
          data={chartData}
          benchmarks={bpBenchmarks}
          height={233}
          showTooltip={true}
          className='relative'
          data-testid='biodiversity-zone-chart'
        />
      </Stack>
    </Stack>
  );
};
