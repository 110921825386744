import { RiCompass4Line, Stack } from '@landler/tw-component-library';
import { Trans, useTranslation } from 'react-i18next';

import { ChartHeading } from '@/components/ChartsV1_2/components/ChartHeading';
import { FloraAndFaunaExplainer } from '@/components/NCAExplainersV1_2/FloraAndFaunaExplainer';
import {
  ExplainerWell,
  NCCard,
  NCCard2SectionLayout,
  NCCardHeader,
  NCCardSectionHeader,
} from '@/components/NCCardV1_2/NCCard';
import { printMonthYear } from '@/utils/formatting/date';

import { Area, AreaChart, Chart, useTimeseriesXAxisProps } from '../../../components/conservation/components/Chart';
import { FactCapsule } from '../../../components/conservation/components/FactCapsule';
import { useFact } from '../../../components/conservation/hooks/useFact';

export const FloraAndFaunaEvolutionCard = () => {
  const { t } = useTranslation();
  return (
    <NCCard explainer={<FloraAndFaunaExplainer />}>
      <NCCardHeader title={t('shared.ncaDetail.floraAndFauna.evolution.title')} />

      <NCCard2SectionLayout className='[&>*]:py-8'>
        <GraphTile />
        <StatsTile />
      </NCCard2SectionLayout>
    </NCCard>
  );
};

const GraphTile = () => {
  const { t } = useTranslation();
  const historic5YearGraph = useFact<{ date: string; value: number }[]>(
    'r1_conservation_flora_and_fauna_historic_index_graph',
  );

  const chartData = historic5YearGraph.value.map((item) => ({
    ...item,
    floraAndFauna: item.value,
    date: new Date(item.date),
  }));

  const xAxisProps = useTimeseriesXAxisProps(chartData, 'date');

  return (
    <Stack center spacing={6}>
      <ChartHeading heading={t('shared.ncaDetail.floraAndFauna.evolution.chartHeading')} />

      <Chart.Container
        data={chartData}
        config={{
          floraAndFauna: {
            label: t('shared.ncaDetail.floraAndFauna.evolution.attributeLabel.floraAndFauna'),
            color: '#4E2F7C',
          },
        }}
      >
        <AreaChart data={chartData}>
          <defs>
            <linearGradient id='floraAndFaunaFill' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='0%' stopColor='#AD98CD' stopOpacity={1} />
              <stop offset='100%' stopColor='#DAD1E9' stopOpacity={1} />
            </linearGradient>
          </defs>
          <Area
            isAnimationActive={false}
            strokeWidth={3}
            stroke='var(--color-floraAndFauna)'
            fill='url(#floraAndFaunaFill)'
            fillOpacity={1}
            activeDot={{ fill: 'var(--color-floraAndFauna)' }}
            dataKey='floraAndFauna'
            connectNulls
          />
          <Chart.XAxis {...xAxisProps} />
          <Chart.YAxis domain={[0, 1]}>
            <Chart.Label>{t('shared.ncaDetail.floraAndFauna.evolution.value')}</Chart.Label>
          </Chart.YAxis>

          <Chart.Tooltip content={(props) => <Chart.TooltipContent {...props} labelFormatter={printMonthYear} />} />

          <Chart.Legend content={(props) => <Chart.LegendContent {...props} />} />
        </AreaChart>
      </Chart.Container>
    </Stack>
  );
};

const StatsTile = () => {
  const { t } = useTranslation();

  const projectAverage = useFact('r1_conservation_flora_and_fauna_average');

  return (
    <Stack spacing={10}>
      <Stack spacing={6}>
        <NCCardSectionHeader
          title={t('shared.ncaDetail.floraAndFauna.evolution.statsHeading')}
          popover={{
            title: t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.floraAndFauna.title'),
            body: t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.floraAndFauna.body'),
          }}
        />
        <Stack spacing={16} className='flex-wrap md:flex-row'>
          <FactCapsule
            label={t('shared.ncaDetail.floraAndFauna.evolution.projectAverage', {
              year: new Date().getFullYear() - 1,
            })}
            fact={projectAverage}
            capsuleIconProps={{ icon: <RiCompass4Line />, variant: 'default' }}
          />
        </Stack>
      </Stack>

      <ExplainerWell
        title={t('global.whatDoesThisMean')}
        body={<Trans i18nKey='shared.ncaDetail.floraAndFauna.evolution.explanation' />}
      />
    </Stack>
  );
};
