import { ButtonLink, RiArrowRightSLine, RiCompass4Line, Stack } from '@landler/tw-component-library';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Capsule, CapsuleContent, CapsuleIcon, CapsuleLabel, CapsuleTrend } from '@/components';
import { ChartHeading } from '@/components/ChartsV1_2/components/ChartHeading';
import { FloraAndFaunaExplainer } from '@/components/NCAExplainersV1_2/FloraAndFaunaExplainer';
import {
  ExplainerWell,
  NCCard,
  NCCard2SectionLayout,
  NCCardHeader,
  NCCardSectionHeader,
} from '@/components/NCCardV1_2/NCCard';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { usePathSet } from '@/hooks/usePathSet';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { buildPath } from '@/utils/buildPath';

import { FactCapsule } from '../../../components/conservation/components/FactCapsule';
import { useFact } from '../../../components/conservation/hooks/useFact';
import { IndicatorSpeciesBreakdownChart } from '../../../components/IndicatorSpeciesBreakdownChart';

export const FloraAndFaunaCard = () => {
  const { t } = useTranslation();
  return (
    <NCCard explainer={<FloraAndFaunaExplainer />}>
      <NCCardHeader title={t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.title')} />

      <NCCard2SectionLayout className='[&>*]:py-8'>
        <GraphTile />
        <StatsTile />
      </NCCard2SectionLayout>
    </NCCard>
  );
};

const GraphTile = () => {
  const { t } = useTranslation();

  return (
    <Stack center spacing={6}>
      <ChartHeading heading={t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.chartHeading')} />
      <IndicatorSpeciesBreakdownChart />
    </Stack>
  );
};

const StatsTile = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const pathSet = usePathSet();

  const projectId = useProjectId();

  const detectedSpeciesCount = useFact('r1_conservation_flora_and_fauna_indicator_species_count');
  const average = useFact('r1_conservation_flora_and_fauna_indicator_species_average');
  const averageTrend = useFact<number>('r1_conservation_flora_and_fauna_indicator_species_average_pct_change');

  const averageTrendDisplay = `${useDisplayNumber(averageTrend?.value ?? 0, { signDisplay: 'always' })}%`;

  return (
    <Stack spacing={10}>
      <Stack spacing={6}>
        <NCCardSectionHeader
          title={t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.statsHeading')}
          popover={{
            title: t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.floraAndFauna.title'),
            body: t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.floraAndFauna.body'),
          }}
        />
        <Stack spacing={16} className='flex-wrap md:flex-row'>
          <Capsule
            label={
              <CapsuleLabel
                infoPopoverProps={{
                  title: t(
                    'shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.indicatorGroupsDetected.title',
                  ),
                  body: t(
                    'shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.indicatorGroupsDetected.body',
                  ),
                }}
              >
                {t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.indicatorGroupsDetected')}
              </CapsuleLabel>
            }
            content={
              <CapsuleContent>
                {t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.detectedSpeciesDisplay', {
                  detectedCount: detectedSpeciesCount.value,
                  totalCount: 10,
                })}
              </CapsuleContent>
            }
            thumbnail={<CapsuleIcon icon={<RiCompass4Line />} variant='default' />}
          />
          <FactCapsule
            label={t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.avgValue', {
              year: new Date().getFullYear() - 1,
            })}
            fact={average}
            trend={
              <CapsuleTrend variant={averageTrend.value < 0 ? 'negative' : 'positive'}>
                {averageTrendDisplay}
              </CapsuleTrend>
            }
            capsuleIconProps={{ icon: <RiCompass4Line />, variant: 'default' }}
            infoPopoverProps={{
              title: t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.averageValue.title', {
                year: new Date().getFullYear() - 1,
              }),
              body: t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.averageValue.body', {
                year: new Date().getFullYear() - 1,
              }),
            }}
          />
        </Stack>
      </Stack>

      <ExplainerWell
        title={t('global.whatDoesThisMean')}
        body={
          <Stack spacing={8}>
            <span>
              <Trans
                i18nKey='shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explanation'
                values={{ detectedCount: detectedSpeciesCount.value, totalCount: 10 }}
              />
            </span>
            <ButtonLink
              to={buildPath(pathSet.v1_2_floraAndFauna, { pathParams: { projectId } })}
              state={{ from: pathname }}
              variant='outline'
              className='md:self-start'
              rightAdornment={<RiArrowRightSLine size={20} />}
            >
              {t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.seeMoreDetails')}
            </ButtonLink>
          </Stack>
        }
      />
    </Stack>
  );
};
