import i18next from 'i18next';

import { getDisplayNumber } from '@/hooks/useDisplayNumber';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ChartXTick = (props: any) => {
  const { x, y, payload, formatter } = props;

  const formattedValue = formatter?.(payload.value) ?? payload.value;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={5} y={0} dy={10} className='typography-caption fill-text-secondary' textAnchor='middle'>
        {formattedValue}
      </text>
    </g>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ChartYTick = (props: any) => {
  const { x, y, payload, formatter } = props;

  const formattedValue =
    formatter?.(payload.value) ??
    /**
     * Since the axis width is limited, we use short notation to fit in large numbers.
     * If number has 4 or more digits, compact notation is applied by default
     * - 'K' for thousands, 'M' for millions, 'T' for trillions, etc as suffixes - depends on the language
     * Choosing between browser and app language to decide on what the suffix should be
     * - we use the app language as this is inline with how the rest of the text of application is formatted/translated
     */
    getDisplayNumber(payload.value, i18next.language, {
      notation: 'compact',
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    });

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={5} className='typography-caption fill-text-secondary' textAnchor='end'>
        {formattedValue}
      </text>
    </g>
  );
};
