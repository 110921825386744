import projectThumbnailSrc from '@assets/images/project-thumbnail.png';
import { FC, HTMLProps, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Divider,
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
  Image,
  RiEditLine,
  RiMore2Line,
  Shimmer,
  Stack,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from 'tw-component-library';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { OrganizationTypeEnum } from '@/api/rest/resources/types/organization';
import { PlotType } from '@/api/rest/resources/types/plot';
import { ProjectStatus } from '@/api/rest/resources/types/project';
import {
  CopyLandStewardEmailButton,
  PlotTypeIcon,
  ProjectStatusBadge,
  ProjectStatusDemoBadge,
  withSuspenseBoundary,
} from '@/components';
import { ProjectThumbnail } from '@/components/MapThumbnail/MapThumbnail';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { useMembershipType } from '@/hooks/useMembershipType';
import { EditProject } from '@/pages/shared/components/EditProject';
import { usePlotsForProject } from '@/pages/shared/hooks/usePlotsForProject';
import {
  ProjectListEnhanced,
  ProjectListEnhancedBuyer,
  ProjectListEnhancedLS,
} from '@/pages/shared/hooks/useProjectsList';
import { getProjectPermissions } from '@/utils/permissions/getProjectPermissions';
import { squareMetersToHectares } from '@/utils/plot';

import { ProjectCardProps } from '../types';

export const ProjectCardMobile: FC<ProjectCardProps> = ({ project }) => {
  const projectStatus = project.status;

  const membershipType = useMembershipType();

  const showCompleteInfo = useMemo(() => {
    if (membershipType === MembershipWithOrganizationTypeEnum.land_steward) return true;

    return project.organization.type === OrganizationTypeEnum.DEMO || projectStatus !== ProjectStatus.initial;
  }, [membershipType, project.organization.type, projectStatus]);

  return (
    <article
      data-testid='projectcard-mobile'
      className='relative grid w-full grid-cols-[24px_1fr_24px] overflow-hidden rounded-2xl bg-white-100'
    >
      {showCompleteInfo ? (
        <CompleteInfo project={project} />
      ) : (
        <MinimalInfo project={project as ProjectListEnhancedBuyer} />
      )}
    </article>
  );
};

const MinimalInfo = ({ project }: { project: ProjectListEnhancedBuyer }) => {
  const { t } = useTranslation();

  const invitationDate = project.invited_at;

  return (
    <>
      <Stack direction='row' className='items-center justify-between border-b border-divider p-6 full-bleed-x'>
        <Stack direction='row' className='flex items-center gap-6'>
          <Thumbnail project={project.id} />
          <DataStack>
            <DataLabel>{t('shared.projects.overviewCard.labels.landSteward')}</DataLabel>
            <DataText>{project.organization.name}</DataText>
          </DataStack>
        </Stack>
      </Stack>
      <div className='col-start-2 flex min-w-0 flex-col gap-10 py-6'>
        <DataStack>
          <DataLabel>{t('shared.projects.overviewCard.labels.invitationDate')}</DataLabel>
          <DataText>{invitationDate ? new Date(invitationDate).toLocaleDateString() : '--'}</DataText>
        </DataStack>
        <CopyLandStewardEmailButton email={project.organization_email} />
      </div>
    </>
  );
};

const CompleteInfo = ({ project }: { project: ProjectListEnhanced }) => {
  const { t } = useTranslation();

  const membershipType = useMembershipType();

  const plotTypes = project.landtypes_allowed;
  const areaDisplay = useDisplayNumber(squareMetersToHectares(project.area));

  const isDemoProject = project.organization.type === OrganizationTypeEnum.DEMO;
  const hasWritePermission = getProjectPermissions(project).includes('write');

  const [isEditingProject, setIsEditingProject] = useState(false);
  return (
    <>
      <div className='flex items-center justify-between p-6 pb-0 full-bleed-x'>
        <div className='flex items-center gap-6'>
          <Thumbnail project={project.id} />
          <DataStack>
            <DataLabel>{t('shared.projects.overviewCard.labels.projectName')}</DataLabel>
            <DataText>{project.name}</DataText>
          </DataStack>
        </div>
      </div>

      <div className='col-start-2 flex min-w-0 flex-col gap-6 py-6'>
        <Divider />

        {(() => {
          if (membershipType === MembershipWithOrganizationTypeEnum.land_steward) {
            return (
              <DataStack>
                <DataLabel>{t('shared.projects.overviewCard.labels.sponsor')}</DataLabel>
                <DataText>{(project as ProjectListEnhancedLS).buyer?.name ?? '--'}</DataText>
              </DataStack>
            );
          }

          return (
            <DataStack>
              <DataLabel>{t('shared.projects.overviewCard.labels.landSteward')}</DataLabel>
              <DataText>{(project as ProjectListEnhancedBuyer).organization.name ?? '--'}</DataText>
            </DataStack>
          );
        })()}
        <Divider />

        <DataStack>
          <DataLabel>{t('shared.projects.overviewCard.labels.location')}</DataLabel>
          <DataText>{project.location_description || t('shared.projects.overviewCard.unknownLocation')}</DataText>
        </DataStack>

        <Divider />
        <DataStack>
          <DataLabel>{t('shared.projects.overviewCard.labels.area')}</DataLabel>
          <DataText>{project.area > 0 ? `${areaDisplay} ha` : '--'}</DataText>
        </DataStack>
        <Divider />

        {plotTypes.length > 0 && (
          <>
            <DataStack>
              <DataLabel>{t('shared.projects.overviewCard.labels.projectLandType')}</DataLabel>
              <DataText>
                {plotTypes.length > 1 ? (
                  t('shared.projects.overviewCard.mixedLandtypes')
                ) : (
                  <Stack direction='row' spacing={1} title={t(`global.plotTypes.${plotTypes[0] as PlotType}`)}>
                    <PlotTypeIcon type={plotTypes[0] as PlotType} size={20} />
                    <span>{t(`global.plotTypes.${plotTypes[0] as PlotType}`)}</span>
                  </Stack>
                )}
              </DataText>
            </DataStack>
            <Divider />
          </>
        )}

        <DataStack>
          <DataLabel>{t('shared.projects.overviewCard.labels.projectStatus')}</DataLabel>
          {isDemoProject ? <ProjectStatusDemoBadge /> : <ProjectStatusBadge project={project} />}
        </DataStack>

        <Stack direction='row' spacing={2} className='mt-4 flex-1'>
          <Button variant='outline' className='flex-1'>
            {t('shared.projects.overviewCard.labels.seeDetailsButton')}
          </Button>

          {membershipType === MembershipWithOrganizationTypeEnum.land_steward && (
            <Dropdown modal={true}>
              <DropdownTrigger data-testid='project-overflow-menu'>
                <div className='shrink-0 rounded-full border border-primary-100 p-2 text-primary-100 hover:border-primary-hover hover:bg-primary-12'>
                  <RiMore2Line size={24} />
                </div>
              </DropdownTrigger>
              <DropdownContent side='bottom' sideOffset={10}>
                {hasWritePermission ? (
                  <DropdownItem leftAdornment={<RiEditLine />} onClick={() => setIsEditingProject(true)}>
                    {t('shared.projects.overviewCard.labels.editProject')}
                  </DropdownItem>
                ) : (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <DropdownItem disabled>{t('shared.projects.overviewCard.labels.editProject')}</DropdownItem>
                    </TooltipTrigger>
                    <TooltipContent sideOffset={5} side='left'>
                      {t('global.tooltips.disabledLockedProject')}
                      <TooltipArrow />
                    </TooltipContent>
                  </Tooltip>
                )}
              </DropdownContent>
            </Dropdown>
          )}
        </Stack>

        <EditProject projectId={project.id} open={isEditingProject} onOpenChange={(o) => setIsEditingProject(o)} />
      </div>
    </>
  );
};

const DataLabel: FC<HTMLProps<HTMLSpanElement>> = (delegated) => (
  <span
    className='typography-overline block truncate pb-2.5 text-text-disabled'
    title={typeof delegated.children === 'string' ? delegated.children : undefined}
    {...delegated}
  />
);

const DataText: FC<HTMLProps<HTMLSpanElement>> = (delegated) => (
  <span
    className='typography-h4 truncate'
    title={typeof delegated.children === 'string' ? delegated.children : undefined}
    {...delegated}
  />
);

const DataStack: FC<HTMLProps<HTMLDivElement>> = (delegated) => <div className='truncate' {...delegated} />;

const Thumbnail: FC<{ project: string }> = withSuspenseBoundary(({ project }) => {
  const plotsQuery = usePlotsForProject(project);
  const plots = plotsQuery.data.results;

  if (!plots) {
    return <Image src={projectThumbnailSrc} className='h-20 w-20 rounded-2xl' alt='project-thumbnail' />;
  }

  return <ProjectThumbnail plots={plots} className='h-20 w-20 rounded-2xl' />;
}, <Shimmer className='h-20 w-20 rounded-2xl' />);
