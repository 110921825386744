import { RiCompass4Line, Stack } from '@landler/tw-component-library';
import { Trans, useTranslation } from 'react-i18next';

import { UnitEnum } from '@/api/rest/resources/types/units';
import { ChartHeading } from '@/components/ChartsV1_2/components/ChartHeading';
import { EcologicalIntegrityExplainer } from '@/components/NCAExplainersV1_2/EcologicalIntegrityExplainer';
import {
  ExplainerWell,
  NCCard,
  NCCard2SectionLayout,
  NCCardHeader,
  NCCardSectionHeader,
} from '@/components/NCCardV1_2/NCCard';
import { getDisplayNumber } from '@/hooks/useDisplayNumber';
import { printMonthYear } from '@/utils/formatting/date';

import { Area, AreaChart, Chart, useTimeseriesXAxisProps } from '../../../components/conservation/components/Chart';
import { FactCapsule } from '../../../components/conservation/components/FactCapsule';
import { useFact } from '../../../components/conservation/hooks/useFact';

export const EcologicalIntegrityCard = () => {
  const { t } = useTranslation();
  return (
    <NCCard explainer={<EcologicalIntegrityExplainer />}>
      <NCCardHeader title={t('shared.ncaDetail.ecologicalIntegrity.historicalData.title')} />

      <NCCard2SectionLayout className='[&>*]:py-8'>
        <GraphTile />
        <StatsTile />
      </NCCard2SectionLayout>
    </NCCard>
  );
};

const GraphTile = () => {
  const { t } = useTranslation();

  const historic5YearGraph = useFact<
    {
      name: 'ecological_integrity';
      date: string;
      value: number;
      unit: UnitEnum.none;
    }[]
  >('r1_conservation_ecological_integrity_historic_graph');

  const chartData = historic5YearGraph.value.map((item) => ({ ...item, ei: item.value, date: new Date(item.date) }));

  const xAxisProps = useTimeseriesXAxisProps(chartData, 'date');

  return (
    <Stack center spacing={6}>
      <ChartHeading heading={t('shared.ncaDetail.ecologicalIntegrity.historicalData.chartHeading')} />

      <Chart.Container
        data={chartData}
        config={{
          ei: {
            label: t('shared.ncaDetail.ecologicalIntegrity.historicalData.attributeLabel.ecologicalIntegrity'),
            color: '#338E5A',
          },
        }}
      >
        <AreaChart data={chartData}>
          <defs>
            <linearGradient id='eiFill' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='0%' stopColor='#7EB796' stopOpacity={1} />
              <stop offset='100%' stopColor='#DBFFEB' stopOpacity={1} />
            </linearGradient>
          </defs>
          <Area
            isAnimationActive={false}
            stroke='var(--color-ei)'
            strokeWidth={3}
            fill='url(#eiFill)'
            fillOpacity={1}
            dataKey='ei'
            connectNulls
          />
          <Chart.XAxis {...xAxisProps} />
          <Chart.YAxis domain={[0, 1]}>
            <Chart.Label>{t('shared.ncaDetail.ecologicalIntegrity.historicalData.value')}</Chart.Label>
          </Chart.YAxis>

          <Chart.Tooltip
            content={(props) => (
              <Chart.TooltipContent
                {...props}
                labelFormatter={printMonthYear}
                formatter={(v) => `${getDisplayNumber((v as number) ?? 0)}`}
              />
            )}
          />

          <Chart.Legend content={(props) => <Chart.LegendContent {...props} />} />
        </AreaChart>
      </Chart.Container>
    </Stack>
  );
};

const StatsTile = () => {
  const { t } = useTranslation();

  const ecologicalIntegrityAvg = useFact('r1_conservation_ecological_integrity_average');
  const ecologicalIntegrityFiveYearChange = useFact('r1_conservation_ecological_integrity_five_year_change');

  return (
    <Stack spacing={10}>
      <Stack spacing={6}>
        <NCCardSectionHeader
          title={t('shared.ncaDetail.ecologicalIntegrity.historicalData.statsHeading')}
          popover={{
            title: t('shared.ncaDetail.ecologicalIntegrity.historicalData.explainers.ecologicalIntegrity.title'),
            body: t('shared.ncaDetail.ecologicalIntegrity.historicalData.explainers.ecologicalIntegrity.body'),
          }}
        />
        <Stack spacing={16} className='flex-wrap md:flex-row'>
          <FactCapsule
            label={t('shared.ncaDetail.ecologicalIntegrity.historicalData.projectAverageValue')}
            fact={ecologicalIntegrityAvg}
            capsuleIconProps={{ icon: <RiCompass4Line />, variant: 'default' }}
            infoPopoverProps={{
              title: t('shared.ncaDetail.ecologicalIntegrity.historicalData.explainers.projectAverageValue.title'),
              body: t('shared.ncaDetail.ecologicalIntegrity.historicalData.explainers.projectAverageValue.body', {
                year: new Date().getFullYear() - 1,
              }),
            }}
          />
          <FactCapsule
            label={t('shared.ncaDetail.ecologicalIntegrity.historicalData.fiveYearChange')}
            fact={ecologicalIntegrityFiveYearChange}
            capsuleIconProps={{ icon: <RiCompass4Line />, variant: 'default' }}
            infoPopoverProps={{
              title: t('shared.ncaDetail.ecologicalIntegrity.historicalData.explainers.fiveYearChange.title'),
              body: t('shared.ncaDetail.ecologicalIntegrity.historicalData.explainers.fiveYearChange.body'),
            }}
          />
        </Stack>
      </Stack>

      <ExplainerWell
        title={t('global.whatDoesThisMean')}
        body={<Trans i18nKey='shared.ncaDetail.ecologicalIntegrity.historicalData.explanation' />}
      />
    </Stack>
  );
};
