import { Project } from '@/api/rest/resources/conservation';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';

const getImage = ({ projectId, name }: { projectId: Project['id']; name: string }) => {
  return new URL(`../mockData/${projectId}/${name}.png`, import.meta.url).href;
};

export const useStaticImage = (name: string) => {
  const projectId = useProjectId();

  return getImage({ projectId, name });
};
