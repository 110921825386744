import { cn, Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { UnitEnum } from '@/api/rest/resources/types/units';
import { ChartHeading } from '@/components/ChartsV1_2/components/ChartHeading';
import { NCCard, NCCard2SectionLayout, NCCardHeader, NCCardSectionHeader } from '@/components/NCCardV1_2/NCCard';
import { getDisplayNumber } from '@/hooks/useDisplayNumber';
import { formatUnit } from '@/utils/formatting';

import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Chart,
  ChartConfig,
  LabelList,
} from '../../../components/conservation/components/Chart';
import { useFact } from '../../../components/conservation/hooks/useFact';

export const DegradationDriversBreakdownCard = () => {
  const { t } = useTranslation();
  return (
    <NCCard>
      <NCCardHeader title={t('shared.ncaDetail.habitatIntactness.degradationDriversBreakdown.title')} />
      <NCCard2SectionLayout className='[&>*]:py-8'>
        <GraphTile />
        <StatsTile />
      </NCCard2SectionLayout>
    </NCCard>
  );
};

const GraphTile = () => {
  const { t } = useTranslation();

  const chartConfig: ChartConfig = {
    buildings: {
      color: '#B6805A',
    },
    mines: {
      color: '#FAD9A3',
    },
    treeLoss: {
      color: '#C97238',
    },
    cropland: {
      color: '#E6B088',
    },
    other: {
      color: '#A27C60',
    },
  };

  const threatsBreakdown = useFact<{ name: keyof typeof chartConfig; '2023': number }[]>(
    'r1_conservation_detected_threats_breakdown',
  );

  const chartData = threatsBreakdown.value;

  return (
    <Stack center spacing={6}>
      <ChartHeading
        heading={t('shared.ncaDetail.habitatIntactness.degradationDriversBreakdown.chartHeading', {
          year: new Date().getFullYear(),
        })}
      />
      <Chart.Container config={chartConfig} data={chartData} height={300}>
        <BarChart layout='vertical' data={chartData}>
          <CartesianGrid strokeDasharray='3 3' horizontal={false} />
          <Chart.XAxis dataKey='2023' type='number' axisLine={false} domain={[0, 0.1]} tickCount={6} />
          <Chart.YAxis
            dataKey='name'
            type='category'
            scale='auto'
            domain={[0, 0.1]}
            padding={{ top: 0 }}
            axisLine={false}
            // @ts-expect-error
            tickFormatter={(v) => t(`shared.ncaDetail.habitatIntactness.degradationDriversBreakdown.${v}`)}
            tick={{ ...(Chart.YAxis.defaultProps?.tick as Record<string, never>), dy: -2 }}
          />
          <Bar dataKey='2023' barSize={16} radius={4} isAnimationActive={false}>
            {Object.entries(chartConfig).map(([key, { color }]) => (
              <Cell key={key} fill={color} />
            ))}
            <LabelList
              dataKey='2023'
              position='right'
              className='typography-caption fill-text-disabled text-text-disabled'
            />
          </Bar>
        </BarChart>
      </Chart.Container>
    </Stack>
  );
};

const StatsTile = () => {
  const { t } = useTranslation();

  type ThreatItem = {
    [k in string]: number;
  } & { name: string };

  const historic5YearGraph = useFact<ThreatItem[]>('r1_conservation_detected_threats_breakdown');

  const latestYear = new Date().getFullYear() - 1;
  const fiveYearsPrior = latestYear - 5;

  const tableData = historic5YearGraph.value;

  return (
    <Stack spacing={10}>
      <Stack spacing={6}>
        <NCCardSectionHeader title={t('shared.ncaDetail.habitatIntactness.degradationDriversBreakdown.statsHeading')} />

        <div className='w-full overflow-x-scroll'>
          <table
            className={cn(
              'typography-body2 w-full table-auto border-collapse overflow-hidden rounded-3xl bg-[#fbfbfb]',
            )}
          >
            <thead>
              <tr className='border-b border-[#e0e0e0] [&>*]:typography-overline [&>*]:p-6 [&>*]:pb-4 [&>*]:text-text-secondary '>
                <th className='text-start'>
                  {t('shared.ncaDetail.habitatIntactness.degradationDriversBreakdown.identifiedThreats')}
                </th>
                <th className='text-end'>{latestYear}</th>
                <th className='text-end'>{fiveYearsPrior}</th>
                <th className='text-end'>
                  {t('shared.ncaDetail.habitatIntactness.degradationDriversBreakdown.change')}
                </th>
              </tr>
            </thead>
            <tbody className='[&>*:not(:last-child)]:border-b [&>*:not(:last-child)]:border-[#e0e0e0]'>
              {tableData.map((threat) => (
                <tr
                  key={threat.name}
                  className='text-text-primary [&>*]:typography-body2 hover:bg-neutral-black-4 [&>*]:px-6 [&>*]:py-2'
                >
                  {/* @ts-expect-error */}
                  <td>{t(`shared.ncaDetail.habitatIntactness.degradationDriversBreakdown.${threat.name}`)}</td>
                  <td className='text-end'>{threat[`${latestYear}` as string]}</td>
                  <td className='text-end'>{threat[`${fiveYearsPrior}` as string]}</td>
                  <PercentChangeCell
                    prior={threat[`${fiveYearsPrior}` as string] as number}
                    latest={threat[`${latestYear}` as string] as number}
                  />
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Stack>
    </Stack>
  );
};

const PercentChangeCell = ({ prior, latest }: { prior: number; latest: number }) => {
  const percentChange = ((prior - latest) / latest) * 100;

  return (
    <td className={cn('text-end', percentChange < 0 ? 'text-error' : 'text-secondary-100')}>
      {getDisplayNumber(percentChange)}
      {formatUnit(UnitEnum['%'])}
    </td>
  );
};
