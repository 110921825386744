import projectThumbnailSrc from '@assets/images/project-thumbnail.png';
import { Icon } from '@landler/component-library';
import { FC, HTMLProps, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
  Image,
  Pill,
  RiEditLine,
  RiMore2Line,
  Shimmer,
  Stack,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from 'tw-component-library';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { OrganizationTypeEnum } from '@/api/rest/resources/types/organization';
import { PlotType } from '@/api/rest/resources/types/plot';
import { ProjectStatus } from '@/api/rest/resources/types/project';
import {
  CopyLandStewardEmailButton,
  PlotTypeIcon,
  ProjectStatusBadge,
  ProjectStatusDemoBadge,
  withSuspenseBoundary,
} from '@/components';
import { ProjectThumbnail } from '@/components/MapThumbnail/MapThumbnail';
import { PendingPill } from '@/components/Pill/PendingPill';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { useFact } from '@/hooks/useFact';
import { useMembershipType } from '@/hooks/useMembershipType';
import { EditProject } from '@/pages/shared/components/EditProject';
import { useNCData } from '@/pages/shared/hooks/useNCData';
import { usePlotsForProject } from '@/pages/shared/hooks/usePlotsForProject';
import {
  ProjectListEnhanced,
  ProjectListEnhancedBuyer,
  ProjectListEnhancedLS,
} from '@/pages/shared/hooks/useProjectsList';
import { formatKilogramsToTonnes } from '@/utils/formatting';
import { getProjectPermissions } from '@/utils/permissions/getProjectPermissions';
import { squareMetersToHectares } from '@/utils/plot';

import { ProjectCardProps } from '../types';

export const ProjectCardDesktop: FC<ProjectCardProps> = ({ project }) => {
  const projectStatus = project.status;

  const membershipType = useMembershipType();

  const isDemoProject = project.organization.type === OrganizationTypeEnum.DEMO;

  const showCompleteInfo = useMemo(() => {
    if (membershipType === MembershipWithOrganizationTypeEnum.land_steward) return true;

    return project.organization.type === OrganizationTypeEnum.DEMO || projectStatus !== ProjectStatus.initial;
  }, [membershipType, project.organization.type, projectStatus]);

  return (
    <article
      data-testid='projectcard-desktop'
      className='relative flex w-full overflow-hidden rounded-2xl bg-white-100'
    >
      <div className='grid w-full grid-cols-[minmax(100px,_200px)_24px_minmax(100px,_330px)_minmax(480px,_auto)] grid-rows-[24px_auto_auto_24px] pr-6'>
        <div className='relative row-span-4'>
          <div className='h-[200px]'>
            <Thumbnail projectId={project.id} />
          </div>
          {isDemoProject ? (
            <ProjectStatusDemoBadge className='absolute top-6 mx-4 max-w-none' data-cy='status-badge' />
          ) : (
            <ProjectStatusBadge project={project} className='absolute top-6 mx-4 max-w-none' data-cy='status-badge' />
          )}
        </div>
        {showCompleteInfo ? (
          <CompleteInfo project={project} />
        ) : (
          <MinimalInfo project={project as ProjectListEnhancedBuyer} />
        )}
      </div>
    </article>
  );
};

const MinimalInfo = ({ project }: { project: ProjectListEnhancedBuyer }) => {
  const { t } = useTranslation();

  const invitationDate = project.invited_at;

  return (
    <>
      <div className='col-start-3 row-start-2 min-w-0 border-b border-b-divider pb-6'>
        <DataStack>
          <DataLabel>{t('shared.projects.overviewCard.labels.landSteward')}</DataLabel>
          <DataText>{project.organization.name}</DataText>
        </DataStack>
      </div>

      <div className='col-start-4 row-start-2 flex min-w-0 items-start justify-between gap-10 border-b border-b-divider pb-6'>
        <div className='ml-5 flex min-w-0 gap-10'></div>

        <CopyLandStewardEmailButton email={project.organization_email} />
      </div>

      <div className='col-start-3 row-start-3 mt-6 min-w-0'>
        <DataStack>
          <DataLabel>{t('shared.projects.overviewCard.labels.invitationDate')}</DataLabel>
          <DataText>{invitationDate ? new Date(invitationDate).toLocaleDateString() : '--'}</DataText>
        </DataStack>
      </div>
    </>
  );
};

const CompleteInfo = ({ project }: { project: ProjectListEnhanced }) => {
  const { t } = useTranslation();

  const membershipType = useMembershipType();

  const plotTypes = project.landtypes_allowed;
  const areaDisplay = useDisplayNumber(squareMetersToHectares(project.area));
  const hasWritePermission = getProjectPermissions(project).includes('write');

  const [isEditingProject, setIsEditingProject] = useState(false);

  return (
    <>
      <div className='col-start-3 row-start-2 min-w-0 border-b border-b-divider pb-6'>
        <DataStack>
          <DataLabel data-cy='project-name-label'>{t('shared.projects.overviewCard.labels.projectName')}</DataLabel>
          <DataText data-cy='project-name-text'>{project.name}</DataText>
        </DataStack>
      </div>

      <div className='col-start-4 row-start-2 flex min-w-0 items-start justify-between gap-10 border-b border-b-divider pb-6'>
        <div className='ml-5 grid w-full max-w-screen-sm grid-cols-3 gap-6'>
          {(() => {
            if (membershipType === MembershipWithOrganizationTypeEnum.land_steward) {
              return (
                <DataStack>
                  <DataLabel data-cy='linked-label'>{t('shared.projects.overviewCard.labels.sponsor')}</DataLabel>
                  <DataText data-cy='linked-text'>{(project as ProjectListEnhancedLS).buyer?.name ?? '--'}</DataText>
                </DataStack>
              );
            }

            return (
              <DataStack>
                <DataLabel data-cy='linked-label'>{t('shared.projects.overviewCard.labels.landSteward')}</DataLabel>
                <DataText data-cy='linked-text'>
                  {(project as ProjectListEnhancedBuyer).organization.name ?? '--'}
                </DataText>
              </DataStack>
            );
          })()}

          <DataStack>
            <DataLabel data-cy='location-label'>{t('shared.projects.overviewCard.labels.location')}</DataLabel>
            <DataText data-cy='location-text'>
              {project.location_description || t('shared.projects.overviewCard.unknownLocation')}
            </DataText>
          </DataStack>

          <DataStack>
            <DataLabel data-cy='area-label'>{t('shared.projects.overviewCard.labels.area')}</DataLabel>
            <DataText data-cy='area-text'>{project.area > 0 ? `${areaDisplay} ha` : '--'}</DataText>
          </DataStack>
        </div>

        <Stack direction='row' spacing={2} className='shrink-0'>
          <Button variant='outline' data-cy='details-button'>
            {t('shared.projects.overviewCard.labels.seeDetailsButton')}
          </Button>
          {membershipType === MembershipWithOrganizationTypeEnum.land_steward && (
            <Dropdown modal={true}>
              <DropdownTrigger data-testid='project-overflow-menu' data-cy='overflow-menu-button'>
                <div className='shrink-0 rounded-full border border-primary-100 p-2 text-primary-100 hover:border-primary-hover hover:bg-primary-12'>
                  <RiMore2Line size={24} data-cy='overflow-menu-icon' />
                </div>
              </DropdownTrigger>
              <DropdownContent side='bottom' sideOffset={10}>
                {hasWritePermission ? (
                  <DropdownItem
                    leftAdornment={<RiEditLine />}
                    onClick={() => setIsEditingProject(true)}
                    data-cy='edit-project-menu-item'
                  >
                    {t('shared.projects.overviewCard.labels.editProject')}
                  </DropdownItem>
                ) : (
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <DropdownItem disabled data-cy='edit-project-menu-item'>
                        {t('shared.projects.overviewCard.labels.editProject')}
                      </DropdownItem>
                    </TooltipTrigger>
                    <TooltipContent sideOffset={5} side='left'>
                      {t('global.tooltips.disabledLockedProject')}
                      <TooltipArrow />
                    </TooltipContent>
                  </Tooltip>
                )}
              </DropdownContent>
            </Dropdown>
          )}
        </Stack>
        <EditProject
          projectId={project.id}
          open={isEditingProject}
          onOpenChange={(open) => setIsEditingProject(open)}
        />
      </div>

      {plotTypes.length > 0 && (
        <div className='col-start-3 row-start-3 mt-6 min-w-0'>
          <DataStack>
            <DataLabel data-cy='landtype-label'>{t('shared.projects.overviewCard.labels.projectLandType')}</DataLabel>
            <DataText data-cy='landtype-text'>
              {plotTypes.length > 1 ? (
                t('shared.projects.overviewCard.mixedLandtypes')
              ) : (
                <Stack direction='row' spacing={1} title={t(`global.plotTypes.${plotTypes[0] as PlotType}`)}>
                  <PlotTypeIcon type={plotTypes[0] as PlotType} size={20} />
                  <span>{t(`global.plotTypes.${plotTypes[0] as PlotType}`)}</span>
                </Stack>
              )}
            </DataText>
          </DataStack>
        </div>
      )}

      <div className='col-start-4 row-start-3 ml-5 mt-6 flex min-w-0 gap-6'>
        <div className='grid w-full max-w-screen-sm grid-cols-3 gap-6'>
          <DataStack className='h-12'>
            <DataLabel data-cy='biodiversity-label'>{t('shared.projects.overviewCard.labels.biodiversity')}</DataLabel>
            <BiodiversityZoneCell projectId={project.id} />
          </DataStack>

          <DataStack className='h-12'>
            <DataLabel data-cy='carbon-label'>{t('shared.projects.overviewCard.labels.carbonStorage')}</DataLabel>
            <CarbonStorageBgCell projectId={project.id} />
          </DataStack>

          <DataStack className='h-12'>
            <DataLabel data-cy='water-label'>{t('shared.projects.overviewCard.labels.waterHolding')}</DataLabel>
            <WaterHoldingCapacityCell projectId={project.id} />
          </DataStack>
        </div>
      </div>
    </>
  );
};

const BiodiversityZoneCell = withSuspenseBoundary(({ projectId }: { projectId: string }) => {
  const biodiversityZone = useFact(useNCData(R1FactType.r1_biodiversity_zone_percent, projectId));
  return (
    <Pill
      size='small'
      leftAdornment={<Icon icon='biodiversity' width={12} height={12} />}
      className='bg-neutral-black-4'
      data-cy='biodiversity-pill'
    >
      {biodiversityZone.display}
    </Pill>
  );
}, <PendingPill />);

const CarbonStorageBgCell = withSuspenseBoundary(({ projectId }: { projectId: string }) => {
  const carbonStorage = useFact(formatKilogramsToTonnes(useNCData(R1FactType.r1_carbon_storage_bg_per_ha, projectId)));

  return (
    <Pill
      size='small'
      leftAdornment={<Icon icon='co2' width={12} height={12} />}
      className='bg-neutral-black-4'
      data-cy='carbon-pill'
    >
      {carbonStorage.display}
    </Pill>
  );
}, <PendingPill />);

const WaterHoldingCapacityCell = withSuspenseBoundary(({ projectId }: { projectId: string }) => {
  const waterHoldingCapacity = useFact(useNCData(R1FactType.r1_water_holding_capacity_per_ha, projectId));

  return (
    <Pill
      size='small'
      leftAdornment={<Icon icon='water' width={12} height={12} />}
      className='bg-neutral-black-4'
      data-cy='water-pill'
    >
      {waterHoldingCapacity.display}
    </Pill>
  );
}, <PendingPill />);

const DataLabel: FC<HTMLProps<HTMLSpanElement>> = (delegated) => (
  <span
    className='typography-overline block truncate pb-2.5 text-text-disabled'
    title={typeof delegated.children === 'string' ? delegated.children : undefined}
    {...delegated}
  />
);

const DataText: FC<HTMLProps<HTMLSpanElement>> = (delegated) => (
  <span
    className='typography-h4 truncate'
    title={typeof delegated.children === 'string' ? delegated.children : undefined}
    {...delegated}
  />
);

const DataStack: FC<HTMLProps<HTMLDivElement>> = (delegated) => (
  <div className='min-w-[50px] max-w-[200px] truncate' {...delegated} />
);

const Thumbnail: FC<{ projectId: string }> = withSuspenseBoundary(({ projectId }) => {
  const plots = usePlotsForProject(projectId).data.results;

  if (!plots) {
    return <Image src={projectThumbnailSrc} className='h-full object-cover' alt='project-thumbnail' />;
  }

  return <ProjectThumbnail plots={plots} className='h-full object-cover' data-cy='thumbnail' />;
}, <Shimmer className='h-full object-cover' />);
