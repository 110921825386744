import { ButtonLink, RiArrowRightUpLine, Stack } from '@landler/tw-component-library';
import { Trans, useTranslation } from 'react-i18next';

import {
  Explainer,
  ExplainerBody,
  ExplainerContent,
  ExplainerCopy,
  ExplainerTitle,
  ExplainerTrigger,
} from './Explainer';

export const FloraAndFaunaExplainer = () => {
  const { t } = useTranslation();

  return (
    <Explainer>
      <ExplainerTrigger data-testid='flora-and-fauna-explainer'>{t('global.howDoWeMeasureThis')}</ExplainerTrigger>
      <ExplainerContent>
        <ExplainerTitle>
          {t('shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.howDoWeMeasure.title')}
        </ExplainerTitle>
        <Stack spacing={8} className='items-start pb-6'>
          <ExplainerBody className='pb-0'>
            <ExplainerCopy
              body={
                <div className='columns-1 leading-[22px] text-text-secondary md:columns-2'>
                  <Trans i18nKey='shared.ncaDetail.ecologicalIntegrity.breakdown.floraAndFauna.explainers.howDoWeMeasure.body' />
                </div>
              }
            />
          </ExplainerBody>
          <ButtonLink to='#' variant='text' rightAdornment={<RiArrowRightUpLine />} className='-left-5'>
            {t('shared.learnMoreAboutMethodology')}
          </ButtonLink>
        </Stack>
      </ExplainerContent>
    </Explainer>
  );
};
