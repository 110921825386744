import { Loader } from '@landler/tw-component-library';
import React from 'react';

export type SuspenseBoundaryProps = React.SuspenseProps;

export const SuspenseBoundary = React.Suspense;

export const withSuspenseBoundary = <T extends object>(
  Component: React.ComponentType<T>,
  fallback?: React.ReactNode,
) => {
  return function Wrapped(props?: T) {
    return (
      <SuspenseBoundary fallback={fallback ?? <Loader />}>
        <Component {...(props as T)} />
      </SuspenseBoundary>
    );
  };
};
