import { useTranslation } from 'react-i18next';
import { Stack } from 'tw-component-library';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { ChartHeading } from '@/components/ChartsV1_2/components/ChartHeading';
import { GraphFact } from '@/components/ChartsV1_2/types';
import { WaterHoldingCapacityChart } from '@/components/ChartsV1_2/WaterHoldingCapacity';
import { useNCCardContext } from '@/components/NCCardV1_2/NCCard';
import { useNCData } from '@/pages/shared/hooks/useNCData';

import { NoProjectData } from '../../../components/NoProjectData';

export const WaterGraphTile = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();

  const waterHoldingCapacity10yearUpliftPotential = useNCData<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.r1_water_holding_capacity_10year_uplift_potential_graph
      : R1FactType.r1_water_holding_capacity_10year_uplift_potential_per_ha_graph,
  );
  const whcData = waterHoldingCapacity10yearUpliftPotential?.value;

  const wiltingPoint10yearUpliftPotential = useNCData<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.r1_below_wilting_point_10year_uplift_potential_graph
      : R1FactType.r1_below_wilting_point_10year_uplift_potential_per_ha_graph,
  );
  const wpData = wiltingPoint10yearUpliftPotential?.value ?? [];

  /**
   * We only null check for whcData since whcData is the primary source data of the graph
   * wpData and other data are reference lines that are displayed only when whcData is rendered
   */
  const noChartData = !whcData?.length;
  if (noChartData) {
    return <NoProjectData />;
  }

  const chartData = [...whcData, ...wpData];

  return (
    <Stack center data-testid='water-uplift-graph-tile'>
      <Stack spacing={2.5} className='w-full items-center justify-center p-6 lg:items-start lg:p-8 lg:pl-2'>
        <ChartHeading
          // TODO: MVP-3284 rename the key
          heading={t('shared.ncaDetail.details.water.labels.upliftGraphHeadline_v1_2')}
          popover={{
            title: t('shared.ncaDetail.details.water.explainers.upliftPotential.title'),
            body: t('shared.ncaDetail.details.water.explainers.upliftPotential.body'),
          }}
        />
        <WaterHoldingCapacityChart
          analysisType={analysisType}
          data={chartData}
          showTooltip={true}
          className='relative'
          data-testid='water-holding-capacity-chart'
        />
      </Stack>
    </Stack>
  );
};
