import { RiCompass4Line, Stack } from '@landler/tw-component-library';
import { Trans, useTranslation } from 'react-i18next';

import { CapsuleTrend } from '@/components';
import { ChartHeading } from '@/components/ChartsV1_2/components/ChartHeading';
import { HabitatIntactnessExplainer } from '@/components/NCAExplainersV1_2/HabitatIntactnessExplainer';
import {
  ExplainerWell,
  NCCard,
  NCCard2SectionLayout,
  NCCardHeader,
  NCCardSectionHeader,
} from '@/components/NCCardV1_2/NCCard';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { printMonthYear } from '@/utils/formatting/date';

import {
  Area,
  AreaChart,
  Chart,
  ReferenceLine,
  useTimeseriesXAxisProps,
} from '../../../components/conservation/components/Chart';
import { FactCapsule } from '../../../components/conservation/components/FactCapsule';
import { useFact } from '../../../components/conservation/hooks/useFact';

export const HabitatIntactnessCard = () => {
  const { t } = useTranslation();
  return (
    <NCCard explainer={<HabitatIntactnessExplainer />}>
      <NCCardHeader title={t('shared.ncaDetail.habitatIntactness.evolution.title')} />
      <NCCard2SectionLayout className='[&>*]:py-8'>
        <GraphTile />
        <StatsTile />
      </NCCard2SectionLayout>
    </NCCard>
  );
};

const GraphTile = () => {
  const { t } = useTranslation();

  const historic5YearGraph = useFact<{ date: string; value: number }[]>(
    'r1_conservation_habitat_intactness_historic_index_graph',
  );

  const chartData = historic5YearGraph.value.map((item) => ({
    ...item,
    hi: item.value,
    date: new Date(item.date),
  }));

  const xAxisProps = useTimeseriesXAxisProps(chartData, 'date');

  return (
    <Stack center spacing={6}>
      <ChartHeading heading={t('shared.ncaDetail.habitatIntactness.evolution.chartHeading')} />

      <Chart.Container
        data={chartData}
        config={{
          hi: {
            label: t('shared.ncaDetail.habitatIntactness.evolution.tooltipLabel'),
            color: '#A56644',
          },
        }}
      >
        <AreaChart data={chartData}>
          <defs>
            <linearGradient id='hiFill' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='0%' stopColor='#D7B688' stopOpacity={1} />
              <stop offset='100%' stopColor='#F1E1AC' stopOpacity={1} />
            </linearGradient>
          </defs>
          <Area
            isAnimationActive={false}
            strokeWidth={3}
            stroke='var(--color-hi)'
            fill='url(#hiFill)'
            fillOpacity={1}
            activeDot={{ fill: 'var(--color-hi)' }}
            dataKey='hi'
            connectNulls
          />
          <ReferenceLine y={0.75} strokeDasharray='3 3' stroke='#0000004D'>
            <Chart.Label
              // @ts-expect-error position accepts string coordinates
              position={{ x: '99%', y: -8 }}
              className='text-neutral-black-60'
            >
              {t('shared.ncaDetail.habitatIntactness.degradationDriversBreakdown.highIntegrity')}
            </Chart.Label>
          </ReferenceLine>
          <ReferenceLine y={0.9} strokeDasharray='3 3' stroke='#0000004D'>
            <Chart.Label
              // @ts-expect-error position accepts string coordinates
              position={{ x: '99%', y: -8 }}
              className='text-neutral-black-60'
            >
              {t('shared.ncaDetail.habitatIntactness.degradationDriversBreakdown.exceptionallyHighIntegrity')}
            </Chart.Label>
          </ReferenceLine>
          <Chart.XAxis {...xAxisProps} />
          <Chart.YAxis domain={[0, 1]}>
            <Chart.Label>{t('shared.ncaDetail.habitatIntactness.evolution.value')}</Chart.Label>
          </Chart.YAxis>

          <Chart.Tooltip content={(props) => <Chart.TooltipContent {...props} labelFormatter={printMonthYear} />} />

          <Chart.Legend content={(props) => <Chart.LegendContent {...props} />} />
        </AreaChart>
      </Chart.Container>
    </Stack>
  );
};

const StatsTile = () => {
  const { t } = useTranslation();

  const baseline = useFact('r1_conservation_habitat_intactness_baseline_2019');
  const average = useFact('r1_conservation_habitat_intactness_average');
  const averageTrend = useFact<number>('r1_conservation_habitat_intactness_average_pct_change');

  const averageTrendDisplay = `${useDisplayNumber(averageTrend?.value ?? 0, { signDisplay: 'always' })}%`;

  return (
    <Stack spacing={10}>
      <Stack spacing={6}>
        <NCCardSectionHeader
          title={t('shared.ncaDetail.habitatIntactness.evolution.statsHeading')}
          popover={{
            title: t('shared.ncaDetail.habitatIntactness.evolution.explainers.habitatIntactness.title'),
            body: t('shared.ncaDetail.habitatIntactness.evolution.explainers.habitatIntactness.body'),
          }}
        />
        <Stack spacing={16} className='flex-wrap md:flex-row'>
          <FactCapsule
            label={t('shared.ncaDetail.habitatIntactness.evolution.baselinePrevFiveYears', {
              year: new Date().getFullYear() - 5,
            })}
            fact={baseline}
            capsuleIconProps={{ icon: <RiCompass4Line />, variant: 'default' }}
            infoPopoverProps={{
              title: t('shared.ncaDetail.habitatIntactness.evolution.explainers.baselineValue.title'),
              body: t('shared.ncaDetail.habitatIntactness.evolution.explainers.baselineValue.body', {
                year: new Date().getFullYear() - 5,
              }),
            }}
          />
          <FactCapsule
            label={t('shared.ncaDetail.habitatIntactness.evolution.avgValue', {
              year: new Date().getFullYear() - 1,
            })}
            fact={average}
            trend={
              <CapsuleTrend variant={averageTrend.value < 0 ? 'negative' : 'positive'}>
                {averageTrendDisplay}
              </CapsuleTrend>
            }
            capsuleIconProps={{ icon: <RiCompass4Line />, variant: 'default' }}
            infoPopoverProps={{
              title: t('shared.ncaDetail.habitatIntactness.evolution.explainers.averageValue.title', {
                year: new Date().getFullYear() - 1,
              }),
              body: t('shared.ncaDetail.habitatIntactness.evolution.explainers.averageValue.body', {
                year: new Date().getFullYear() - 1,
              }),
            }}
          />
        </Stack>
      </Stack>

      <ExplainerWell
        title={t('global.whatDoesThisMean')}
        body={<Trans i18nKey='shared.ncaDetail.habitatIntactness.evolution.explanation' />}
      />
    </Stack>
  );
};
