import {
  ButtonLink,
  InfoPopover,
  RiArrowRightSLine,
  RiArrowRightUpLine,
  RiCoinLine,
  RiCompass4Line,
  RiDownloadLine,
  RiPriceTag3Line,
  Stack,
} from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { NCCard, NCCardHeader } from '@/components';
import { ChartHeading } from '@/components/ChartsV1_2/components/ChartHeading';
import { NatureUnitBalanceExplainer } from '@/components/NCAExplainersV1_2/NatureUnitBalanceExplainer';
import { usePathSet } from '@/hooks/usePathSet';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { buildPath } from '@/utils/buildPath';

import { FactCapsule } from '../components/FactCapsule';
import { useFact } from '../hooks/useFact';
import { useStaticImage } from '../hooks/useStaticImage';

export const NcaPage = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const pathSet = usePathSet();
  const projectId = useProjectId();

  const natureUnitsCurrent = useFact('r1_conservation_nature_units_current');
  const natureUnitsAvg = useFact('r1_conservation_nature_units_average');
  const natureUnits5YearChange = useFact('r1_conservation_nature_units_5_year_change');
  const natureUnitsHighest = useFact('r1_conservation_nature_units_highest');
  const natureUnitsLowest = useFact('r1_conservation_nature_units_lowest');
  const ecologicalIntegrityAvg = useFact('r1_conservation_ecological_integrity_average');
  const ecologicalIntegrityBaseline2019 = useFact('r1_conservation_ecological_integrity_baseline_2019');

  const ecologicalIntegrityMapImage = useStaticImage('ecological_integrity');

  return (
    <Stack spacing={10}>
      <span className='typography-overline'>{t('shared.projects.labels.naturalCapitalAccount')}</span>
      <NCCard explainer={<NatureUnitBalanceExplainer />} data-testid='nature-unit-balance'>
        <NCCardHeader title={t('shared.ncaDetail.details.natureUnitBalance.title')} />

        <Stack spacing={10} className='w-full px-10 py-6 md:flex-row md:items-start [&>*]:flex-1'>
          <Stack spacing={6}>
            <Stack className='items-start rounded-2xl bg-bg-light-grey p-6' spacing={4}>
              <FactCapsule
                label={t('shared.ncaDetail.details.natureUnitBalance.labels.numberOfNatureUnits')}
                capsuleIconProps={{ icon: <RiCoinLine />, variant: 'default' }}
                fact={natureUnitsCurrent}
              />
              <Stack spacing={2} className='items-start'>
                <span className='typography-body1 text-text-secondary'>
                  {t('shared.ncaDetail.details.natureUnitBalance.explainers.numberOfNatureUnits.body')}
                </span>
                <ButtonLink to='#' variant='text' rightAdornment={<RiArrowRightUpLine />} className='-left-5'>
                  {t('shared.learnMoreAboutMethodology')}
                </ButtonLink>
              </Stack>
            </Stack>

            <Stack spacing={8}>
              <span className='typography-body1'>
                {t('shared.ncaDetail.details.natureUnitBalance.labels.natureUnitValueFromEcologicalIntegrity')}
              </span>
              <Stack direction='row' className='flex-wrap gap-16'>
                <FactCapsule
                  label={t('shared.ncaDetail.details.natureUnitBalance.labels.avgValue', {
                    year: new Date().getFullYear() - 1,
                  })}
                  capsuleIconProps={{ icon: <RiPriceTag3Line />, variant: 'default' }}
                  fact={natureUnitsAvg}
                  infoPopoverProps={{
                    title: t('shared.ncaDetail.details.natureUnitBalance.explainers.averageValue.title', {
                      year: new Date().getFullYear() - 1,
                    }),
                    body: t('shared.ncaDetail.details.natureUnitBalance.explainers.averageValue.body', {
                      year: new Date().getFullYear() - 1,
                    }),
                  }}
                />
                <FactCapsule
                  label={t('shared.ncaDetail.details.natureUnitBalance.labels.5YearChange')}
                  capsuleIconProps={{ icon: <RiArrowRightUpLine />, variant: 'default' }}
                  fact={natureUnits5YearChange}
                  signed
                  infoPopoverProps={{
                    title: t('shared.ncaDetail.details.natureUnitBalance.explainers.5YearChange.title'),
                    body: t('shared.ncaDetail.details.natureUnitBalance.explainers.5YearChange.body'),
                  }}
                />
                <FactCapsule
                  label={t('shared.ncaDetail.details.natureUnitBalance.labels.highestValue')}
                  capsuleIconProps={{ icon: <RiDownloadLine className='rotate-180' />, variant: 'default' }}
                  fact={natureUnitsHighest}
                  infoPopoverProps={{
                    title: t('shared.ncaDetail.details.natureUnitBalance.explainers.highestValue.title'),
                    body: t('shared.ncaDetail.details.natureUnitBalance.explainers.highestValue.body'),
                  }}
                />
                <FactCapsule
                  label={t('shared.ncaDetail.details.natureUnitBalance.labels.lowestValue')}
                  capsuleIconProps={{ icon: <RiDownloadLine />, variant: 'default' }}
                  fact={natureUnitsLowest}
                  infoPopoverProps={{
                    title: t('shared.ncaDetail.details.natureUnitBalance.explainers.lowestValue.title'),
                    body: t('shared.ncaDetail.details.natureUnitBalance.explainers.lowestValue.body'),
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
          <Stack spacing={6} className='rounded-2xl bg-bg-light-grey p-6'>
            <ChartHeading
              heading={t('shared.ncaDetail.details.ecologicalIntegrity.title')}
              popover={
                <InfoPopover
                  title={t('shared.ncaDetail.details.ecologicalIntegrity.explainers.ecologicalIntegrity.title')}
                  body={t('shared.ncaDetail.details.ecologicalIntegrity.explainers.ecologicalIntegrity.body')}
                  popoverTriggerProps={{
                    className: 'text-text-secondary',
                  }}
                />
              }
            />
            <Stack spacing={6} centerMain className='flex-col-reverse justify-between md:flex-row'>
              <Stack spacing={6}>
                <Stack spacing={10} className='py-3'>
                  <FactCapsule
                    label={t('shared.ncaDetail.details.ecologicalIntegrity.labels.projectAverageValue')}
                    capsuleIconProps={{ icon: <RiCompass4Line />, variant: 'default' }}
                    fact={ecologicalIntegrityAvg}
                    infoPopoverProps={{
                      title: t('shared.ncaDetail.details.ecologicalIntegrity.explainers.projectAverageValue.title'),
                      body: t('shared.ncaDetail.details.ecologicalIntegrity.explainers.projectAverageValue.body', {
                        year: new Date().getFullYear() - 1,
                      }),
                    }}
                  />
                  <FactCapsule
                    label={t('shared.ncaDetail.details.ecologicalIntegrity.labels.baselineValuePrevFiveYears', {
                      year: new Date().getFullYear() - 5,
                    })}
                    capsuleIconProps={{ icon: <RiCompass4Line />, variant: 'default' }}
                    fact={ecologicalIntegrityBaseline2019}
                    infoPopoverProps={{
                      title: t('shared.ncaDetail.details.ecologicalIntegrity.explainers.baselineValue.title'),
                      body: t('shared.ncaDetail.details.ecologicalIntegrity.explainers.baselineValue.body'),
                    }}
                  />
                </Stack>
                <Stack spacing={2} className='p-2'>
                  <div className='h-3 w-full max-w-[222px] rounded-sm bg-gradient-to-r from-[#FFFACA] to-[#0C5326]' />
                  <Stack direction='row' className='justify-between'>
                    {[0.0, 0.2, 0.4, 0.6, 0.8, 1.0].map((num) => (
                      <span key={num} className='typography-caption text-text-secondary'>
                        {Intl.NumberFormat(window.navigator.language, {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(num)}
                      </span>
                    ))}
                  </Stack>
                </Stack>
              </Stack>

              <img
                src={ecologicalIntegrityMapImage}
                className='max-h-[265px] min-w-0 max-w-full'
                alt={t('shared.ncaDetail.details.ecologicalIntegrity.labels.ecologicalIntegrityMap')}
              />
            </Stack>

            <Stack spacing={4}>
              <ButtonLink
                to={buildPath(pathSet.v1_2_ecologicalIntegrity, { pathParams: { projectId } })}
                state={{ from: pathname }}
                variant='outline'
                rightAdornment={<RiArrowRightSLine size={20} />}
                className='self-start'
              >
                {t('shared.ncaDetail.details.ecologicalIntegrity.labels.seeMoreDetails')}
              </ButtonLink>

              <span className='typography-caption text-text-secondary'>
                {t('shared.ncaDetail.details.ecologicalIntegrity.explainers.ecologicalIntegrityScope')}
              </span>
            </Stack>
          </Stack>
        </Stack>
      </NCCard>
    </Stack>
  );
};
