import { useTranslation } from 'react-i18next';

import { UnitEnum } from '@/api/rest/resources/types/units';
import { formatUnit } from '@/utils/formatting';
import { printMonthYear } from '@/utils/formatting/date';

import { Area, AreaChart, Chart, useTimeseriesXAxisProps } from './conservation/components/Chart';
import { useFact } from './conservation/hooks/useFact';

export const HabitatIntactnessHistoricChart = () => {
  const { t } = useTranslation();

  const historic5YearGraph = useFact<{ date: string; value: number }[]>(
    'r1_conservation_habitat_intactness_historic_graph',
  );

  const chartData = historic5YearGraph.value.map((item) => ({
    ...item,
    intact: item.value,
    notIntact: 100,
    date: new Date(item.date),
  }));

  const xAxisProps = useTimeseriesXAxisProps(chartData, 'date');

  return (
    <Chart.Container
      data={chartData}
      config={{
        intact: {
          label: t('shared.ncaDetail.ecologicalIntegrity.breakdown.habitatIntactness.attributeLabel.intact'),
          color: '#CEF09A',
        },
        notIntact: {
          label: t('shared.ncaDetail.ecologicalIntegrity.breakdown.habitatIntactness.attributeLabel.notIntact'),
          color: '#558B00',
          tooltip: false,
        },
      }}
    >
      <AreaChart data={chartData}>
        <defs>
          <linearGradient id='intactFill' x1='0' y1='0' x2='0' y2='1'>
            <stop offset='0%' stopColor='#B2E997' stopOpacity={1} />
            <stop offset='100%' stopColor='#D8F3AF' stopOpacity={1} />
          </linearGradient>
          <linearGradient id='notIntactFill' x1='0' y1='0' x2='0' y2='1'>
            <stop offset='0%' stopColor='#49700D' stopOpacity={1} />
            <stop offset='100%' stopColor='#558B00' stopOpacity={1} />
          </linearGradient>
        </defs>
        <Area
          isAnimationActive={false}
          strokeWidth={0}
          fill='url(#notIntactFill)'
          fillOpacity={1}
          activeDot={false}
          dataKey='notIntact'
          connectNulls
        />
        <Area
          isAnimationActive={false}
          strokeWidth={0}
          fill='url(#intactFill)'
          fillOpacity={1}
          activeDot={{ fill: 'var(--color-ni)' }}
          dataKey='intact'
          connectNulls
        />
        <Chart.XAxis {...xAxisProps} axisLine={false} />
        <Chart.YAxis
          padding={{ top: 0 }}
          domain={[0, 100]}
          tickFormatter={(v) => `${v}${formatUnit(UnitEnum['%'])}`}
          axisLine={false}
        />

        <Chart.Tooltip
          content={(props) => (
            <Chart.TooltipContent
              {...props}
              labelFormatter={printMonthYear}
              formatter={(v) => `${v}${formatUnit(UnitEnum['%'])}`}
            />
          )}
        />

        <Chart.Legend content={(props) => <Chart.LegendContent {...props} />} />
      </AreaChart>
    </Chart.Container>
  );
};
