import { cn, Container, Loader, Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import { SecondaryNav, SuspenseBoundary, TabsList, TabTrigger } from '@/components';
import { usePathSet } from '@/hooks/usePathSet';
import { SignedInPageLayout } from '@/layout/signed-in-page';
import { useMenuItems } from '@/pages/shared/hooks/useMenuItems';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { buildPath } from '@/utils/buildPath';

import { ProjectMap } from './components/ProjectMap';
import { ProjectOverview } from './components/ProjectOverview';
import { useProject } from './hooks/useProject';
import { LandMonitoringPage } from './pages/land-monitoring.page';
import { NcaPage } from './pages/nca.page';
import { OverviewPage } from './pages/overview.page';

export const ConservationProjectPage = () => {
  const { t } = useTranslation();

  const menuItems = useMenuItems();
  const projectId = useProjectId();
  const { data: project } = useProject();
  const pathSet = usePathSet();

  return (
    <SignedInPageLayout items={menuItems} withCustomContentContainer>
      <SecondaryNav title={project.name} backPath={pathSet.projects} />
      <Container gutterWidth={{ sm: 4 }} className='my-10' data-testid='conservation-project-page'>
        <Stack spacing={10}>
          <div className={cn('grid grid-rows-[auto] gap-10', 'md:w-auto md:grid-cols-2')}>
            <ProjectMap />
            <ProjectOverview />
          </div>

          <Stack>
            <TabsList className='mb-10 justify-start sm:justify-center'>
              <TabTrigger path={buildPath(pathSet.v1_2_projectDetails, { pathParams: { projectId } })}>
                {t('shared.projects.labels.overview')}
              </TabTrigger>
              <TabTrigger path={buildPath(pathSet.v1_2_nca, { pathParams: { projectId } })}>
                {t('shared.projects.labels.naturalCapitalAccount')}
              </TabTrigger>
              <TabTrigger path={buildPath(pathSet.v1_2_landMonitoring, { pathParams: { projectId } })}>
                {t('shared.projects.labels.landMonitoring')}
              </TabTrigger>
            </TabsList>

            <SuspenseBoundary
              fallback={
                <Stack center className='h-[600px]'>
                  <Loader />
                </Stack>
              }
            >
              <Routes>
                <Route index element={<OverviewPage />} />
                <Route path='nca' element={<NcaPage />} />
                <Route path='land-monitoring' element={<LandMonitoringPage />} />
              </Routes>
            </SuspenseBoundary>
          </Stack>
        </Stack>
      </Container>
    </SignedInPageLayout>
  );
};
