import { ButtonLink, Divider, Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { PlotReportFactElement, R1FactType } from '@/api/rest/resources/types/fact';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { UnitEnum } from '@/api/rest/resources/types/units';
import {
  getNCChangeIcon,
  getNCChangeIconVariant,
  NCCardSectionHeader,
  NCFactCapsule,
  NCFactCapsuleProps,
  printLatestDate,
  useNCCardContext,
} from '@/components';
import { useContactFormLink } from '@/hooks/useContactFormLink';
import { useFact } from '@/hooks/useFact';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useScreenSize } from '@/hooks/useScreenSize';
import { useNCData } from '@/pages/shared/hooks/useNCData';
import { formatKilogramsToTonnes } from '@/utils/formatting/formatKgToTon';

export const CarbonCardStatsTile = () => {
  const isLargeScreen = useScreenSize() === 'large';

  return (
    <Stack className='gap-8 py-8 lg:gap-4' data-testid='carbon-stats-tile'>
      <BelowGroundCarbonStorage />

      {isLargeScreen && <Divider className='border-dashed' />}

      <GHGEmissions />

      {isLargeScreen && <Divider className='border-dashed' />}

      <UpliftPotential />
    </Stack>
  );
};

const BelowGroundCarbonStorage = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();

  const carbonBgLatestAnalysis = formatKilogramsToTonnes(
    useNCData(
      analysisType === 'total'
        ? R1FactType.r1_carbon_bg_latest_analysis_total
        : R1FactType.r1_carbon_bg_latest_analysis_per_ha,
    ),
  );

  const carbonBgSincePreviousAnalysis = formatKilogramsToTonnes(
    useNCData(
      analysisType === 'total'
        ? R1FactType.r1_carbon_bg_previous_1y_uplift_total
        : R1FactType.r1_carbon_bg_previous_1y_uplift_per_ha,
    ),
  );

  const equivalentExplainer = useEquivalentsExplainer();

  const latestAnalysisDate = printLatestDate([carbonBgLatestAnalysis?.measured_at]);

  const latestAnalysisBody = t('shared.ncaDetail.details.explainers.latestAnalysis.body', {
    date: latestAnalysisDate,
  });

  const popoverCarbonBgLatestAnalysis: NCFactCapsuleProps['infoPopoverProps'] = equivalentExplainer
    ? {
        title: t('shared.ncaDetail.details.explainers.latestAnalysis.title'),
        body: `${latestAnalysisBody}\n${equivalentExplainer}`,
        popoverTriggerProps: {
          'data-testid': 'carbon-bg-latest-analysis-popover',
        },
      }
    : undefined;

  const carbonBgSincePreviousAnalysisInitialMeasuredAt = printLatestDate([carbonBgSincePreviousAnalysis?.measured_at]);
  const carbonBgSincePreviousAnalysisPreviousMeasuredAt = printLatestDate([
    carbonBgSincePreviousAnalysis?.previous_1y_measured_at,
  ]);

  return (
    <div className='flex flex-col gap-8 lg:gap-4'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.carbon.labels.belowTheGroundCarbonStorage')}
        supplementaryInfo={`${t('shared.ncaDetail.details.labels.latestAnalysis')} ${latestAnalysisDate}`}
        popover={{
          title: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.title'),
          body: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.body'),
        }}
      />
      <Stack direction='row' className='flex-wrap gap-16'>
        <NCFactCapsule
          label={t('shared.ncaDetail.details.labels.latestAnalysis')}
          fact={carbonBgLatestAnalysis}
          capsuleIconProps={{
            icon: getNCChangeIcon(carbonBgLatestAnalysis),
            variant: getNCChangeIconVariant(carbonBgLatestAnalysis),
          }}
          infoPopoverProps={popoverCarbonBgLatestAnalysis}
        />
        {!!carbonBgSincePreviousAnalysis?.value && (
          <NCFactCapsule
            label={t('shared.ncaDetail.details.labels.sincePreviousAnalysis')}
            fact={carbonBgSincePreviousAnalysis}
            capsuleIconProps={{
              icon: getNCChangeIcon(carbonBgSincePreviousAnalysis),
              variant: getNCChangeIconVariant(carbonBgSincePreviousAnalysis),
            }}
            infoPopoverProps={{
              title: t('shared.ncaDetail.details.carbon.explainers.sincePreviousAnalysis.title'),
              body: t('shared.ncaDetail.details.carbon.explainers.sincePreviousAnalysis.body', {
                date: carbonBgSincePreviousAnalysisInitialMeasuredAt,
                previousDate: carbonBgSincePreviousAnalysisPreviousMeasuredAt,
              }),
              popoverTriggerProps: {
                'data-testid': 'whc-since-previous-analysis-tooltip',
              },
            }}
          />
        )}
      </Stack>
    </div>
  );
};

const GHGEmissions = () => {
  const { t } = useTranslation();
  const membershipType = useMembershipType();
  const contactLink = useContactFormLink();
  const { analysisType } = useNCCardContext();

  const ghgEmissionsPerYearLatestEstimates =
    formatKilogramsToTonnes(
      useNCData(
        analysisType === 'total'
          ? R1FactType.r1_ghg_emissions_per_year_latest_analysis_total
          : R1FactType.r1_ghg_emissions_per_year_latest_analysis_per_ha,
      ),
    ) ?? ({} as PlotReportFactElement);

  const latestAnalysisDate = printLatestDate([ghgEmissionsPerYearLatestEstimates.measured_at]);

  const isDataProvided = !!ghgEmissionsPerYearLatestEstimates?.value;

  const ncChangeIcon = getNCChangeIcon(ghgEmissionsPerYearLatestEstimates);
  const ncChangeIconVariant = getNCChangeIconVariant(ghgEmissionsPerYearLatestEstimates);

  // TODO: cleanup/refactor with changes of MVP-2324
  if (!isDataProvided && membershipType === MembershipWithOrganizationTypeEnum.land_steward) {
    ghgEmissionsPerYearLatestEstimates.value = t('global.misc.provideData');
    ghgEmissionsPerYearLatestEstimates.unit = UnitEnum.none;
  }

  const latestEstimatesLabel = t('shared.ncaDetail.details.labels.latestEstimates');

  return (
    <div className='flex flex-col gap-8 lg:gap-4'>
      {membershipType === MembershipWithOrganizationTypeEnum.land_steward ? (
        <>
          <NCCardSectionHeader
            title={t('shared.ncaDetail.details.carbon.labels.ghgEmissionsPerYear')}
            supplementaryInfo={isDataProvided ? `${latestEstimatesLabel} ${latestAnalysisDate}` : null}
            popover={{
              title: t('shared.ncaDetail.details.carbon.explainers.ghgEmissionsPerYear.title'),
              body: t('shared.ncaDetail.details.carbon.explainers.ghgEmissionsPerYear.body'),
            }}
          />
          <div className='flex flex-col justify-between gap-8 sm:flex-row sm:items-center'>
            <NCFactCapsule
              className={isDataProvided ? undefined : 'text-text-disabled'}
              label={isDataProvided ? latestEstimatesLabel : t('shared.ncaDetail.details.labels.noEstimates')}
              fact={ghgEmissionsPerYearLatestEstimates}
              capsuleIconProps={{
                icon: ncChangeIcon,
                variant: ncChangeIconVariant,
              }}
              infoPopoverProps={
                isDataProvided
                  ? {
                      title: t('shared.ncaDetail.details.carbon.explainers.ghgEmissionsPerYearLatestEstimates.title'),
                      body: t('shared.ncaDetail.details.carbon.explainers.ghgEmissionsPerYearLatestEstimates.body', {
                        date: latestAnalysisDate,
                      }),
                    }
                  : {
                      body: t(
                        'shared.ncaDetail.details.carbon.explainers.ghgEmissionsPerYearLatestEstimates.noEstimates.body',
                      ),
                    }
              }
            />
            {!isDataProvided && (
              <ButtonLink variant='outline' target='_blank' to={contactLink}>
                {t('notFound.labels.contactSupportButton')}
              </ButtonLink>
            )}
          </div>
        </>
      ) : (
        <>
          <NCCardSectionHeader
            title={t('shared.ncaDetail.details.carbon.labels.ghgEmissionsPerYear')}
            supplementaryInfo={`${latestEstimatesLabel} ${latestAnalysisDate}`}
            popover={{
              title: t('shared.ncaDetail.details.carbon.explainers.ghgEmissionsPerYear.title'),
              body: t('shared.ncaDetail.details.carbon.explainers.ghgEmissionsPerYear.body'),
            }}
          />
          <NCFactCapsule
            label={latestEstimatesLabel}
            fact={ghgEmissionsPerYearLatestEstimates}
            capsuleIconProps={{
              icon: getNCChangeIcon(ghgEmissionsPerYearLatestEstimates),
              variant: getNCChangeIconVariant(ghgEmissionsPerYearLatestEstimates),
            }}
            infoPopoverProps={{
              title: t('shared.ncaDetail.details.explainers.latestAnalysis.title'),
              body: t('shared.ncaDetail.details.explainers.latestAnalysis.body', {
                date: latestAnalysisDate,
              }),
            }}
          />
        </>
      )}
    </div>
  );
};

const UpliftPotential = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();

  const carbonBg1YearUpliftPotential = formatKilogramsToTonnes(
    useNCData<number>(
      analysisType === 'total'
        ? R1FactType.r1_carbon_bg_1year_uplift_potential_total
        : R1FactType.r1_carbon_bg_1year_uplift_potential_per_ha,
    ),
  );
  const carbonBg10YearUpliftPotential = formatKilogramsToTonnes(
    useNCData<number>(
      analysisType === 'total'
        ? R1FactType.r1_carbon_bg_10year_uplift_potential_total
        : R1FactType.r1_carbon_bg_10year_uplift_potential_per_ha,
    ),
  );

  const equivalentExplainer = useEquivalentsExplainer();

  const popover1Year: NCFactCapsuleProps['infoPopoverProps'] = equivalentExplainer
    ? {
        title: t('shared.ncaDetail.details.carbon.explainers.1YearPotential.title'),
        body: `${t('shared.ncaDetail.details.carbon.explainers.1YearPotential.body')}\n${equivalentExplainer}`,
        popoverTriggerProps: {
          'data-testid': 'carbon-bg-1-year-uplift-potential-popover',
        },
      }
    : undefined;

  const popover10Year: NCFactCapsuleProps['infoPopoverProps'] = equivalentExplainer
    ? {
        title: t('shared.ncaDetail.details.carbon.explainers.10YearPotential.title'),
        body: `${t('shared.ncaDetail.details.carbon.explainers.10YearPotential.body')}\n${equivalentExplainer}`,
        popoverTriggerProps: {
          'data-testid': 'carbon-bg-10-year-uplift-potential-popover',
        },
      }
    : undefined;

  return (
    <div className='flex flex-col gap-8 lg:gap-4'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.carbon.labels.upliftPotentialForCarbonStorage')}
        popover={{
          title: t('shared.ncaDetail.details.carbon.explainers.upliftPotentialForBelowTheGroundCarbonStorage.title'),
          body: t('shared.ncaDetail.details.carbon.explainers.upliftPotentialForBelowTheGroundCarbonStorage.body'),
        }}
      />
      <Stack direction='row' className='flex-wrap gap-16'>
        <NCFactCapsule
          label={t('shared.ncaDetail.details.labels.1YearPotential')}
          fact={carbonBg1YearUpliftPotential}
          capsuleIconProps={{
            icon: getNCChangeIcon(carbonBg1YearUpliftPotential),
            variant: getNCChangeIconVariant(carbonBg1YearUpliftPotential),
          }}
          infoPopoverProps={popover1Year}
        />
        <NCFactCapsule
          label={t('shared.ncaDetail.details.labels.10YearPotential')}
          fact={carbonBg10YearUpliftPotential}
          capsuleIconProps={{
            icon: getNCChangeIcon(carbonBg10YearUpliftPotential),
            variant: getNCChangeIconVariant(carbonBg10YearUpliftPotential),
          }}
          infoPopoverProps={popover10Year}
        />
      </Stack>
    </div>
  );
};

const useEquivalentsExplainer = () => {
  const { t } = useTranslation();

  const { analysisType } = useNCCardContext();

  const carbonStorageBg = formatKilogramsToTonnes(
    useNCData(
      analysisType === 'total' ? R1FactType.r1_carbon_storage_bg_total : R1FactType.r1_carbon_storage_bg_per_ha,
    ),
  );
  const carbonStorageBgCo2Equivalent = formatKilogramsToTonnes(
    useNCData(
      analysisType === 'total'
        ? R1FactType.r1_carbon_storage_bg_co2_equivalent_total
        : R1FactType.r1_carbon_storage_bg_co2_equivalent_per_ha,
    ),
  );

  const { display: carbonStorageBgDisplay, value: carbonStorageBgValue } = useFact(carbonStorageBg);

  const { display: carbonStorageBgCo2EquivalentDisplay, value: carbonStorageBgCo2EquivalentValue } =
    useFact(carbonStorageBgCo2Equivalent);

  if (!carbonStorageBgValue || !carbonStorageBgCo2EquivalentValue) return undefined;

  return t('shared.ncaDetail.details.carbon.explainers.equivalents', {
    cValue: carbonStorageBgDisplay,
    co2Value: carbonStorageBgCo2EquivalentDisplay,
  });
};
