import { RiCompass4Line, Stack } from '@landler/tw-component-library';
import { Trans, useTranslation } from 'react-i18next';

import { Capsule, CapsuleContent, CapsuleIcon, CapsuleLabel } from '@/components';
import { ChartHeading } from '@/components/ChartsV1_2/components/ChartHeading';
import {
  ExplainerWell,
  NCCard,
  NCCard2SectionLayout,
  NCCardHeader,
  NCCardSectionHeader,
} from '@/components/NCCardV1_2/NCCard';

import { useFact } from '../../../components/conservation/hooks/useFact';
import { HabitatIntactnessHistoricChart } from '../../../components/HabitatIntactnessHistoricChart';

export const DegradationDriversCard = () => {
  const { t } = useTranslation();
  return (
    <NCCard>
      <NCCardHeader title={t('shared.ncaDetail.habitatIntactness.degradationDrivers.title')} />
      <NCCard2SectionLayout className='[&>*]:py-8'>
        <GraphTile />
        <StatsTile />
      </NCCard2SectionLayout>
    </NCCard>
  );
};

const GraphTile = () => {
  const { t } = useTranslation();

  return (
    <Stack center spacing={6}>
      <ChartHeading heading={t('shared.ncaDetail.habitatIntactness.degradationDrivers.chartHeading')} />

      <HabitatIntactnessHistoricChart />
    </Stack>
  );
};

const StatsTile = () => {
  const { t } = useTranslation();

  const detectedThreatsCount = useFact<number>('r1_conservation_detected_threats_count');

  return (
    <Stack spacing={10}>
      <Stack spacing={6}>
        <NCCardSectionHeader title={t('shared.ncaDetail.habitatIntactness.degradationDrivers.statsHeading')} />
        <Stack spacing={16} className='flex-wrap md:flex-row'>
          <Capsule
            label={
              <CapsuleLabel>
                {t('shared.ncaDetail.habitatIntactness.degradationDrivers.remoteSensedThreats')}
              </CapsuleLabel>
            }
            content={
              <CapsuleContent>
                {t('shared.ncaDetail.habitatIntactness.degradationDrivers.remoteSensedThreatsDisplay', {
                  detectedCount: detectedThreatsCount.value,
                  totalCount: 6,
                })}
              </CapsuleContent>
            }
            thumbnail={<CapsuleIcon icon={<RiCompass4Line />} variant='default' />}
          />
        </Stack>
      </Stack>

      <ExplainerWell
        title={t('global.whatDoesThisMean')}
        body={
          <Trans
            i18nKey='shared.ncaDetail.habitatIntactness.degradationDrivers.explanation'
            values={{ detectedCount: detectedThreatsCount.value, totalCount: 6 }}
          />
        }
      />
    </Stack>
  );
};
