import { FC, PropsWithChildren } from 'react';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { SecondaryNav } from '@/components';
import { useMembershipType } from '@/hooks/useMembershipType';
import { SignedInPageLayoutContentContainer } from '@/layout/signed-in-page/components';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { StackedPageLayout } from '@/pages/shared/layout/StackedPage.layout';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';

interface LandMonitoringPageWrapperProps {
  title: string;
}

export const LandMonitoringPageWrapper: FC<PropsWithChildren<LandMonitoringPageWrapperProps>> = ({
  title,
  children,
}) => {
  const membershipType = useMembershipType();
  const projectId = useProjectId();

  return (
    <StackedPageLayout withCustomContentContainer>
      <SecondaryNav
        backPath={buildPath(
          membershipType === MembershipWithOrganizationTypeEnum.land_steward
            ? paths.landSteward.v1_2_projectDetails
            : paths.buyer.v1_2_projectDetails,
          { pathParams: { projectId } },
        )}
        title={title}
      />
      <SignedInPageLayoutContentContainer>{children}</SignedInPageLayoutContentContainer>
    </StackedPageLayout>
  );
};
