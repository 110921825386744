import { cn } from '@landler/tw-component-library';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Area, ComposedChart, Label, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { UnitEnum } from '@/api/rest/resources/types/units';
import { getDisplayNumber } from '@/hooks/useDisplayNumber';
import { Logger } from '@/lib/logs/logger';
import { useBenchmarkForFact } from '@/pages/shared/hooks/useBenchmarkForFact';
import { colorPalette } from '@/theme/colorPalette';
import { formatUnit } from '@/utils/formatting';
import { printMonthYear } from '@/utils/formatting/date';
import { getStyleForBenchmark } from '@/utils/getStyleForBenchmark';

import { ChartXTick, ChartYTick } from './components/Axis';
import { ChartLegendBar } from './components/LegendBar';
import {
  ChartTooltip,
  ChartTooltipDot,
  ChartTooltipTextPrimary,
  ChartTooltipTextSecondary,
} from './components/Tooltip';
import { ChartLegendBarItem } from './components/types';
import { RenderConfidenceInterval } from './ConfidenceInterval';
import { SHARED_CHART_COLORS } from './constants';
import { NoChartData } from './NoChartData';
import { extrapolateReferenceLinesChartData, RenderReferenceLine } from './ReferenceLines';
import { ChartProps } from './types';
import { getChartDataFromGraphFact, getXAxisDomainForHistoricGraph, sortChartData } from './utils';

const chartColors = {
  biodiversity: { stroke: '#76AC23', fill: ['#5D9900', '#558707'] },
} as const;

const factNames = {
  biodiversity_percent: 'bp',
  biodiversity_buffer_zone_pct_benchmark_low: 'bp_benchmark_low',
  biodiversity_buffer_zone_pct_benchmark_moderate: 'bp_benchmark_moderate',
  biodiversity_buffer_zone_pct_benchmark_high: 'bp_benchmark_high',
};

const unit = formatUnit(UnitEnum['%']);

export const BiodiversityZoneChart: FC<HTMLAttributes<HTMLDivElement> & ChartProps> = ({
  data,
  benchmarks,
  height = 400,
  width = '100%',
  showTooltip = true,
  className,
  children,
  ...delegated
}) => {
  const { t } = useTranslation();
  const styles = cn('flex w-full flex-col', className);

  const dateDataMap = getChartDataFromGraphFact(data, factNames);
  const sortedChartData = sortChartData(dateDataMap, (date) => new Date(date).getTime());

  let chartData = extrapolateReferenceLinesChartData(sortedChartData, 'bp_benchmark_low');
  chartData = extrapolateReferenceLinesChartData(chartData, 'bp_benchmark_moderate');
  chartData = extrapolateReferenceLinesChartData(chartData, 'bp_benchmark_high');

  const firstTick = chartData.at(0);
  const lastTick = chartData.at(-1);

  if (!firstTick || !lastTick) {
    Logger.error('Not enough data to render chart');
    return (
      <div className={cn(styles, 'h-full justify-center')} {...delegated}>
        <NoChartData />
      </div>
    );
  }

  const xTicks = getXAxisDomainForHistoricGraph(firstTick.label, lastTick.label);

  return (
    <div className={styles} {...delegated}>
      <ResponsiveContainer width={width} height={height}>
        <ComposedChart
          data={chartData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id='biodiversityZone' x1='0' y1='1' x2='0' y2='0'>
              <stop offset='4.32%' stopColor={chartColors.biodiversity.fill[0]} stopOpacity={0} />
              <stop offset='128.14%' stopColor={chartColors.biodiversity.fill[1]} stopOpacity={0.3} />
            </linearGradient>
          </defs>
          {RenderConfidenceInterval({
            dataKey: 'bp.confidence_interval',
            type: 'monotone',
          })}
          <Area
            isAnimationActive={false}
            type='monotone'
            dataKey='bp.value'
            stroke={chartColors.biodiversity.stroke}
            strokeWidth={1}
            fill='url(#biodiversityZone)'
            fillOpacity={1}
            activeDot={ChartTooltipDot}
            connectNulls
          />
          {RenderReferenceLine({
            dataKey: 'bp_benchmark_low.value',
            type: 'monotone',
            label: chartData.some((point) => !!point.bp_benchmark_low) ? t('global.benchmark.low') : null,
            chartData,
          })}
          {RenderReferenceLine({
            dataKey: 'bp_benchmark_moderate.value',
            type: 'monotone',
            label: chartData.some((point) => !!point.bp_benchmark_moderate) ? t('global.benchmark.moderate') : null,
            chartData,
          })}
          {RenderReferenceLine({
            dataKey: 'bp_benchmark_high.value',
            type: 'monotone',
            label: chartData.some((point) => !!point.bp_benchmark_high) ? t('global.benchmark.high') : null,
            chartData,
          })}
          <XAxis
            dataKey='label'
            stroke={SHARED_CHART_COLORS.axis.stroke}
            tickLine={false}
            tick={<ChartXTick formatter={printMonthYear} />}
            ticks={xTicks}
            tickFormatter={printMonthYear}
            padding={{ right: 31 }}
            scale='time'
            type='number'
            domain={['dataMin', 'dataMax']}
          />
          <YAxis
            stroke={SHARED_CHART_COLORS.axis.stroke}
            tickLine={false}
            type='number'
            scale='sequential'
            domain={['dataMin', 'dataMax']}
            tick={<ChartYTick />}
            padding={{ top: 25 }}
            width={40}
          >
            <Label
              className='typography-caption text-text-secondary'
              value={formatUnit(UnitEnum['%'])}
              position={{ x: 32, y: 10 }}
            />
          </YAxis>
          {showTooltip && (
            <Tooltip
              cursor={{ stroke: colorPalette.divider }}
              position={{ y: 0 }}
              allowEscapeViewBox={{ x: true }}
              animationDuration={150}
              content={(content) => <CustomTooltip {...content} benchmarks={benchmarks} />}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
      <CustomLegendBar />
      {children}
    </div>
  );
};

const CustomLegendBar = () => {
  const { t } = useTranslation();

  const legendBarItems: ChartLegendBarItem[] = [
    {
      label: t('global.analysis.biodiversityZone'),
      color: chartColors.biodiversity.fill[0],
    },
  ];

  return (
    <ChartLegendBar items={legendBarItems} className='mt-8 sm:ml-[28px]' data-testid='biodiversity-zone-chart-legend' />
  );
};

const CustomTooltip = ({
  active,
  payload,
  label,
  benchmarks,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
TooltipProps<ValueType, NameType> & { benchmarks?: any[] }) => {
  const { t } = useTranslation();

  const bpValue = payload?.find((data) => data.dataKey === 'bp.value');
  const bpDisplay =
    bpValue?.value != null
      ? `${getDisplayNumber(bpValue?.value as string, window.navigator.language)} ${unit}`
      : t('global.analysis.noData');
  const bpBenchmarkType = useBenchmarkForFact(benchmarks, bpValue?.value as string);

  if (!active) return null;

  const style = bpBenchmarkType ? getStyleForBenchmark(bpBenchmarkType) : null;

  return (
    <ChartTooltip>
      {bpBenchmarkType && (
        <ChartTooltipTextPrimary
          className='typography-overline mt-1 w-fit rounded px-[9px] py-2'
          style={{
            color: style?.text,
            backgroundColor: style?.primary,
          }}
        >
          {t(`global.benchmark.${bpBenchmarkType}`)}
        </ChartTooltipTextPrimary>
      )}
      <ChartTooltipTextPrimary>{`${t('global.analysis.biodiversityZone')}: ${bpDisplay}`}</ChartTooltipTextPrimary>
      <ChartTooltipTextSecondary>{printMonthYear(label)}</ChartTooltipTextSecondary>
    </ChartTooltip>
  );
};
