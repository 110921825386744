import { RiArrowDownSLine, RiArrowLeftSLine, RiArrowRightSLine, Stack } from '@landler/tw-component-library';
import * as Accordion from '@radix-ui/react-accordion';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from 'tw-component-library/utils';

import { prefersReducedMotion } from '@/utils/motionPreference';

import { useProject } from '../hooks/useProject';
import { useStaticImage } from '../hooks/useStaticImage';

export const OverviewPage = () => {
  const { t } = useTranslation();

  return (
    <Accordion.Root className='flex flex-col gap-6' type='single' defaultValue='about' collapsible>
      <AccordionItem value='about'>
        <AccordionTrigger>{t('shared.ncaDetail.details.projectOverview.labels.about')}</AccordionTrigger>
        <AccordionContent>
          <About />
        </AccordionContent>
      </AccordionItem>

      <AccordionItem value='species'>
        <AccordionTrigger>{t('shared.ncaDetail.details.projectOverview.labels.species')}</AccordionTrigger>
        <AccordionContent>
          <Species />
        </AccordionContent>
      </AccordionItem>

      <AccordionItem value='community'>
        <AccordionTrigger>{t('shared.ncaDetail.details.projectOverview.labels.community')}</AccordionTrigger>
        <AccordionContent>
          <Community />
        </AccordionContent>
      </AccordionItem>
    </Accordion.Root>
  );
};

const AccordionItem = React.forwardRef<
  React.ComponentRef<typeof Accordion.Item>,
  React.ComponentProps<typeof Accordion.Item>
>(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Item className={cn('overflow-hidden rounded-3xl bg-white-100', className)} {...props} ref={forwardedRef}>
    {children}
  </Accordion.Item>
));
AccordionItem.displayName = 'AccordionItem';

const AccordionTrigger = React.forwardRef<
  React.ComponentRef<typeof Accordion.Trigger>,
  React.ComponentProps<typeof Accordion.Trigger>
>(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Header className='flex'>
    <Accordion.Trigger
      className={cn(
        'group typography-h4 flex flex-1 items-center justify-between px-8 py-6 outline-none',
        'transition-colors duration-150 hover:bg-primary-12',
        className,
      )}
      {...props}
      ref={forwardedRef}
    >
      {children}
      <RiArrowDownSLine className='transition-transform group-data-[state=open]:rotate-180' aria-hidden />
    </Accordion.Trigger>
  </Accordion.Header>
));
AccordionTrigger.displayName = 'AccordionTrigger';

const AccordionContent = React.forwardRef<
  React.ComponentRef<typeof Accordion.Content>,
  React.ComponentProps<typeof Accordion.Content>
>(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Content
    className={cn(
      'overflow-hidden border-t border-divider px-8 py-6',
      'data-[state=closed]:animate-accordionClose',
      'data-[state=open]:animate-accordionOpen',
      className,
    )}
    {...props}
    ref={forwardedRef}
  >
    {children}
  </Accordion.Content>
));
AccordionContent.displayName = 'AccordionContent';

const Figure = ({ name, ...delegated }: React.ComponentProps<typeof Stack> & { name: string }) => {
  const src = useStaticImage(name);

  return (
    <Stack spacing={4} {...delegated} asChild>
      <figure>
        <img src={src} alt='about' className='w-full rounded-3xl' />
        <figcaption>
          <Stack className='ms-2'>
            <span className='typography-body2Semibold'>Image title</span>
            <span className='typography-body2 text-text-secondary'>(Image subtitle)</span>
          </Stack>
        </figcaption>
      </figure>
    </Stack>
  );
};

type ImageCarouselProps = Omit<React.ComponentProps<typeof Stack>, 'children'> & {
  images: string[];
};

const ImageCarousel = ({ className, images, ...delegated }: ImageCarouselProps) => {
  const figureWidthPx = 309;

  const carouselRef = useRef<React.ComponentRef<typeof Stack>>(null);

  const scrollCarousel = (direction: 'left' | 'right') => {
    carouselRef.current?.scrollBy({
      left: direction === 'left' ? 0 - figureWidthPx : figureWidthPx,
      behavior: prefersReducedMotion ? 'auto' : 'smooth',
    });
  };

  return (
    <Stack
      spacing={6}
      style={
        {
          '--figure-width-px': figureWidthPx,
        } as React.CSSProperties
      }
    >
      <Stack
        ref={carouselRef}
        direction='row'
        spacing={4}
        className={cn('min-w-0 overflow-x-auto whitespace-nowrap', className)}
        {...delegated}
      >
        {images.map((image) => (
          <Figure key={image} name={image} className='w-[calc(var(--figure-width-px)_*_1px)] flex-[0_0_auto]' />
        ))}
      </Stack>
      <Stack direction='row' spacing={2.5} className='justify-center'>
        <button
          onClick={() => scrollCarousel('left')}
          className={cn(
            'flex h-8 w-8 items-center justify-center',
            'rounded-full border border-primary-100 hover:bg-primary-12',
          )}
        >
          <RiArrowLeftSLine />
        </button>
        <button
          onClick={() => scrollCarousel('right')}
          className={cn(
            'flex h-8 w-8 items-center justify-center',
            'rounded-full border border-primary-100 hover:bg-primary-12',
          )}
        >
          <RiArrowRightSLine />
        </button>
      </Stack>
    </Stack>
  );
};

const About = () => {
  const { data: project } = useProject();

  return (
    <Stack className='gap-4 md:flex-row md:gap-8'>
      <p
        className='flex-[1] text-text-secondary [&_p]:mb-4'
        dangerouslySetInnerHTML={{ __html: project.overview.about.body }}
      />
      <Stack direction='row' spacing={4} className='flex-[2]'>
        {project.overview.about.images.map((image) => (
          <Figure key={image} name={image} />
        ))}
      </Stack>
    </Stack>
  );
};

const Species = () => {
  const { data: project } = useProject();

  return (
    <Stack spacing={6} className='min-w-0'>
      <p
        className='text-text-secondary md:columns-2 [&_p]:mb-4'
        dangerouslySetInnerHTML={{ __html: project.overview.species.body }}
      />
      <ImageCarousel images={project.overview.species.images} />
    </Stack>
  );
};

const Community = () => {
  const { data: project } = useProject();
  return (
    <Stack spacing={6}>
      <p
        className='text-text-secondary md:columns-2 [&_p]:mb-4'
        dangerouslySetInnerHTML={{ __html: project.overview.community.body }}
      />
      <ImageCarousel images={project.overview.community.images} />
    </Stack>
  );
};
