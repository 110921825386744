import { Stack } from '@landler/tw-component-library';
import { scaleTime } from 'd3-scale';
import React, { forwardRef, useId, useMemo } from 'react';
import {
  Label,
  LabelProps,
  Legend,
  LegendProps,
  ResponsiveContainer,
  ResponsiveContainerProps,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts-overriden';
import { Payload } from 'recharts-overriden/types/component/DefaultTooltipContent';
import { cn } from 'tw-component-library/utils';

import { SHARED_CHART_COLORS } from '@/components/ChartsV1_2/constants';
import { colorPalette } from '@/theme/colorPalette';
import { printYear } from '@/utils/formatting/date';

export type ChartConfig = {
  [k in string]: {
    label?: React.ReactNode;
  } & {
    /**
     * Color of the indicator used in the tooltip and legend.
     */
    color: string;
    /**
     * Type of the indicator used in the tooltip and legend.
     * @default 'dot'
     */
    icon?: 'dot' | 'line' | 'dashed' | (React.ReactNode & Record<never, never>);
    /**
     * Whether to show this item in the tooltip.
     * @default true
     */
    tooltip?: boolean;
    /**
     * Whether to show this item in the legend.
     * @default true
     */
    legend?: boolean;
  };
};

type ChartData = Record<string, unknown>[];

type ChartContextProps = {
  config: ChartConfig;
  availableAttributes: string[];
};

const ChartContext = React.createContext<ChartContextProps | null>(null);

function useChart() {
  const context = React.useContext(ChartContext);

  if (!context) {
    throw new Error('useChart must be used within a <ChartContainer />');
  }

  return context;
}

export type ChartContainerProps = React.ComponentProps<'div'> & {
  config: ChartConfig;
  data: ChartData;
  children: ResponsiveContainerProps['children'];
  /** @default 400 */
  height?: ResponsiveContainerProps['height'];
  width?: ResponsiveContainerProps['width'];
};

const ChartContainer = forwardRef<HTMLDivElement, ChartContainerProps>(
  ({ id, config: configProp, data: chartData, className, height = 400, width, children, ...delegated }, ref) => {
    const uniqueId = useId();
    const chartId = `chart-${id ?? uniqueId.replaceAll(/:/g, '')}`;

    const chartConfig = Object.entries(configProp).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: {
          ...value,
          icon: value.icon ?? 'dot',
          tooltip: value.tooltip ?? true,
          legend: value.legend ?? true,
        },
      }),
      {} satisfies ChartConfig,
    );

    const availableAttributes = useMemo(() => {
      const attributes = new Set<string>();

      Object.keys(chartConfig).forEach((attribute) => {
        chartData.forEach((item) => {
          // eslint-disable-next-line security/detect-object-injection
          if (item[attribute] != null) {
            attributes.add(attribute);
          }
        });
      });

      return [...attributes];
    }, [chartConfig, chartData]);

    return (
      <ChartContext.Provider value={{ config: chartConfig, availableAttributes }}>
        <div ref={ref} data-chart={chartId} className={cn('w-full', className)} {...delegated}>
          <ChartStyles chartId={chartId} chartConfig={chartConfig} />
          <ResponsiveContainer height={height} width={width}>
            {children}
          </ResponsiveContainer>
        </div>
      </ChartContext.Provider>
    );
  },
);
ChartContainer.displayName = 'ChartContainer';

const Y_AXIS_WIDTH_PX = 50;

type ChartStylesProps = {
  chartId: string;
  chartConfig: ChartConfig;
};

const ChartStyles = ({ chartId, chartConfig }: ChartStylesProps) => {
  const colorConfig = Object.entries(chartConfig).filter(([, c]) => c.color);

  if (!colorConfig.length) {
    return null;
  }

  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `
[data-chart=${chartId}] {
  --y-axis-width-px: ${Y_AXIS_WIDTH_PX}; /* keep unitless */
${colorConfig
  .map(([key, itemConfig]) => {
    const { color } = itemConfig;
    return color ? `  --color-${key}: ${color};` : null;
  })
  .join('\n')}
}
`,
      }}
    />
  );
};

function getPayloadConfigFromPayload(config: ChartConfig, payload: unknown, key: string) {
  if (typeof payload !== 'object' || payload === null) {
    return undefined;
  }

  const payloadPayload =
    'payload' in payload && typeof payload.payload === 'object' && payload.payload !== null
      ? payload.payload
      : undefined;

  let configLabelKey: string = key;

  if (key in payload && typeof payload[key as keyof typeof payload] === 'string') {
    configLabelKey = payload[key as keyof typeof payload] as string;
  } else if (
    payloadPayload &&
    key in payloadPayload &&
    typeof payloadPayload[key as keyof typeof payloadPayload] === 'string'
  ) {
    configLabelKey = payloadPayload[key as keyof typeof payloadPayload] as string;
  }

  return configLabelKey in config
    ? // eslint-disable-next-line security/detect-object-injection
      config[configLabelKey]
    : config[key as keyof typeof config];
}

const Indicator = ({ icon = 'dot', color }: Pick<ChartConfig[string], 'color' | 'icon'>) => {
  const style = { '--indicator-fill': color } as React.CSSProperties;

  if (icon === 'dot') {
    return <div className='mx-[1px] aspect-square w-2 shrink-0 rounded-full bg-[--indicator-fill]' style={style} />;
  }

  if (icon === 'line') {
    return <div className='h-0.5 w-3 shrink-0 rounded bg-[--indicator-fill]' style={style} />;
  }

  if (icon === 'dashed') {
    return <div className='w-3 shrink-0 border-t-2 border-dashed border-[--indicator-fill]' style={style} />;
  }

  return <>{icon ?? null}</>;
};

const ChartTooltip = Tooltip;
ChartTooltip.displayName = Tooltip.displayName;
ChartTooltip.defaultProps = {
  ...Tooltip.defaultProps,
  // @ts-expect-error
  cursor: { stroke: colorPalette.divider },
  position: { y: 0 },
  allowEscapeViewBox: { x: true, y: true },
  isAnimationActive: !window.matchMedia('(prefers-reduced-motion: reduce)').matches,
  animationDuration: 150,
};

type PayloadItem = Payload<string | number | (string | number)[], string | number>;

export type ChartTooltipContentProps = React.ComponentProps<typeof Tooltip> & React.ComponentProps<'div'>;

export const ChartTooltipContent = forwardRef<HTMLDivElement, ChartTooltipContentProps>(
  ({ active, payload, className, label, labelFormatter, formatter }, ref) => {
    const { config } = useChart();

    const formattedLabel = React.useMemo(() => {
      if (!payload?.length) {
        return null;
      }

      if (labelFormatter) {
        return labelFormatter(label, payload);
      }

      return label.toString?.() ?? label;
    }, [label, labelFormatter, payload]);

    if (!active || !payload?.length) return null;

    return (
      <Stack
        ref={ref}
        direction='col'
        spacing={1.5}
        className={cn('rounded-md bg-white-100 px-2 py-1 elevation-1', className)}
      >
        {formattedLabel && (
          <span className='typography-body2 mt-1 inline-block text-primary-100'>{formattedLabel}</span>
        )}
        <Stack spacing={1}>
          {payload.map((item, index) => {
            if (!item.value || !item.name) {
              return null;
            }
            const key = `${item.name || item.dataKey || 'value'}`;
            const itemConfig = getPayloadConfigFromPayload(config, item, key);

            if (!itemConfig?.tooltip) {
              return null;
            }

            const formattedValue = formatter?.(item.value, item.name, item, index, item.payload) ?? item.value;
            return (
              <Stack key={item.dataKey} direction='row' centerMain className='justify-between' spacing={4}>
                <Stack direction='row' spacing={2} centerMain>
                  <Indicator color={itemConfig.color} icon={itemConfig?.icon} />
                  <span className='typography-body2 text-text-secondary'>{itemConfig?.label ?? item.name}</span>
                </Stack>
                <span className='typography-body2Semibold mt-px inline-block text-primary-100'>{formattedValue}</span>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    );
  },
);
ChartTooltipContent.displayName = 'ChartTooltipContent';

const ChartLegend = Legend;
ChartLegend.displayName = Legend.displayName;
ChartLegend.defaultProps = {
  ...Legend.defaultProps,
  verticalAlign: 'bottom',
  align: 'right',
  // @ts-expect-error
  wrapperStyle: {
    paddingLeft: 'calc(calc(var(--y-axis-width-px) * 1px) - 4px)',
    paddingTop: '32px',
  },
};

export type ChartLegendContentProps = Pick<LegendProps, 'payload'> & {
  onAttributeHover?: (dataKey: PayloadItem['dataKey'] | null) => void;
};

const ChartLegendContent = ({ payload, onAttributeHover }: ChartLegendContentProps) => {
  const { config, availableAttributes } = useChart();

  if (!payload?.length) {
    return null;
  }

  return (
    <Stack
      direction='row'
      centerMain
      className={cn('max-w-fit flex-wrap gap-y-0.5 rounded-lg bg-bg-light-grey p-[3px]')}
      onMouseLeave={() => onAttributeHover?.(null)}
    >
      {(payload as PayloadItem[]).map((item) => {
        const key = `${item.dataKey || 'value'}`;

        if (!availableAttributes.includes(key)) {
          return null;
        }

        const itemConfig = getPayloadConfigFromPayload(config, item, key);

        if (!itemConfig?.legend) {
          return null;
        }

        return (
          <Stack
            key={item.dataKey}
            direction='row'
            spacing={2}
            centerMain
            className={cn('rounded-md py-1 pl-2 pr-[10px]', onAttributeHover && 'hover:bg-neutral-black-8')}
            onMouseOver={() => onAttributeHover?.(key)}
          >
            <Indicator color={itemConfig.color} icon={itemConfig?.icon} />
            <span className='typography-caption text-text-secondary'>{itemConfig?.label ?? item.name}</span>
          </Stack>
        );
      })}
    </Stack>
  );
};

const axisDefaults = {
  tick: { fill: '#00000099', fontSize: 12 },
  stroke: SHARED_CHART_COLORS.axis.stroke,
  tickLine: false,
  type: 'number',
  tickMargin: 16,
} satisfies XAxisProps;

type TimeseriesItem = ConstructorParameters<typeof Date>[0];

const ChartXAxis = XAxis;
ChartXAxis.displayName = XAxis.displayName;
ChartXAxis.defaultProps = {
  ...XAxis.defaultProps,
  ...axisDefaults,
  type: 'number',
};

export const useTimeseriesXAxisProps = (chartData: ChartData, dataKey: XAxisProps['dataKey'] = 'date') => {
  const timeseries = chartData.map((item) => new Date(item[dataKey as string] as TimeseriesItem).valueOf());

  const domain = [Math.min(...timeseries), Math.max(...timeseries)];

  const scale = scaleTime().domain(domain).nice();

  return {
    scale,
    domain,
    dataKey,
    tickFormatter: printYear,
    ticks: [
      domain[0] as number,
      ...scale
        .ticks(5)
        .map((date) => date.valueOf())
        .slice(1),
    ],
  };
};

const ChartYAxis = YAxis;
ChartYAxis.displayName = YAxis.displayName;
ChartYAxis.defaultProps = {
  ...YAxis.defaultProps,
  ...axisDefaults,
  tick: { ...axisDefaults.tick, dy: -5 },
  padding: { top: 50 },
  width: Y_AXIS_WIDTH_PX,
  scale: 'sequential',
} satisfies YAxisProps;

const ChartLabel = ({ className, ...delegated }: LabelProps) => (
  <Label
    className={cn('typography-caption text-text-secondary', className)}
    position={{ x: Y_AXIS_WIDTH_PX - 20, y: 10 }}
    {...delegated}
  />
);
ChartLabel.displayName = Label.displayName;

export * from 'recharts-overriden';

export const Chart = {
  Container: ChartContainer,
  XAxis: ChartXAxis,
  YAxis: ChartYAxis,
  Label: ChartLabel,
  Tooltip: ChartTooltip,
  TooltipContent: ChartTooltipContent,
  Legend: ChartLegend,
  LegendContent: ChartLegendContent,
};
