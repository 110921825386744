import { cn } from '@landler/tw-component-library';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Area, ComposedChart, Label, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { UnitEnum } from '@/api/rest/resources/types/units';
import { getDisplayNumber } from '@/hooks/useDisplayNumber';
import { Logger } from '@/lib/logs/logger';
import { colorPalette } from '@/theme/colorPalette';
import { formatUnit } from '@/utils/formatting';
import { printMonthYear } from '@/utils/formatting/date';

import { ChartXTick, ChartYTick } from './components/Axis';
import { ChartLegendBar } from './components/LegendBar';
import {
  ChartTooltip,
  ChartTooltipDot,
  ChartTooltipTextPrimary,
  ChartTooltipTextSecondary,
} from './components/Tooltip';
import { ChartLegendBarItem } from './components/types';
import { RenderConfidenceInterval } from './ConfidenceInterval';
import { SHARED_CHART_COLORS } from './constants';
import { NoChartData } from './NoChartData';
import { extrapolateReferenceLinesChartData, RenderReferenceLine } from './ReferenceLines';
import { ChartProps } from './types';
import { getChartDataFromGraphFact, getXAxisDomainForPotentialGraph, minMaxAxisDomain, sortChartData } from './utils';

const chartColors = {
  whc: { stroke: '#3EC1DC', fill: '#85DAFF' },
} as const;

const totalFactNames = {
  water_holding_capacity_potential: 'whc',
  below_wilting_point_potential: 'wp',
};

const perHaFactNames = {
  water_holding_capacity_potential_per_ha: 'whc',
  below_wilting_point_potential_per_ha: 'wp',
};

export const WaterHoldingCapacityChart: FC<HTMLAttributes<HTMLDivElement> & ChartProps> = ({
  analysisType,
  data,
  height = 400,
  width = '100%',
  showTooltip = true,
  className,
  children,
  ...delegated
}) => {
  const { t } = useTranslation();

  const styles = cn('flex w-full flex-col', className);
  const unit = analysisType === 'total' ? formatUnit(UnitEnum['m^3']) : formatUnit(UnitEnum['m^3/ha']);

  const factNames = analysisType === 'total' ? totalFactNames : perHaFactNames;
  const dateDataMap = getChartDataFromGraphFact(data, factNames);
  const sortedChartData = sortChartData(dateDataMap, (date) => new Date(date).getTime());
  const chartData = extrapolateReferenceLinesChartData(sortedChartData, 'wp');

  const firstTick = chartData.at(0);
  const lastTick = chartData.at(-1);

  if (!firstTick || !lastTick) {
    Logger.error('Not enough data to render chart');
    return (
      <div className={cn(styles, 'h-full justify-center')} {...delegated}>
        <NoChartData />
      </div>
    );
  }

  const xTicks = getXAxisDomainForPotentialGraph(firstTick.label, lastTick.label);

  return (
    <div className={styles} {...delegated}>
      <ResponsiveContainer width={width} height={height}>
        <ComposedChart
          data={chartData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id='waterHoldingCapacity' x1='0' y1='-0.86' x2='0' y2='1'>
              <stop offset='0%' stopColor={chartColors.whc.fill} stopOpacity={1} />
              <stop offset='100%' stopColor={chartColors.whc.fill} stopOpacity={0} />
            </linearGradient>
          </defs>
          {RenderConfidenceInterval({
            dataKey: 'whc.confidence_interval',
            type: 'monotone',
          })}
          <Area
            isAnimationActive={false}
            type='monotone'
            dataKey='whc.value'
            stroke={chartColors.whc.stroke}
            strokeWidth={3}
            fill='url(#waterHoldingCapacity)'
            fillOpacity={1}
            activeDot={(props) => ChartTooltipDot(props)}
            connectNulls
          />
          {RenderReferenceLine({
            dataKey: 'wp.value',
            type: 'step',
            label: chartData.some((point) => !!point.wp) ? t('global.analysis.wiltingPoint') : null,
            chartData,
          })}

          <XAxis
            dataKey='label'
            tickLine={false}
            tick={<ChartXTick formatter={printMonthYear} />}
            ticks={xTicks}
            tickFormatter={printMonthYear}
            type='number'
            scale='time'
            domain={['dataMin', 'dataMax']}
            padding={{ right: 25 }}
            stroke={SHARED_CHART_COLORS.axis.stroke}
          />
          <YAxis
            tickLine={false}
            type='number'
            scale='sequential'
            domain={minMaxAxisDomain(0.97, 1.03)}
            tick={<ChartYTick />}
            padding={{ top: 25 }}
            width={65}
            stroke={SHARED_CHART_COLORS.axis.stroke}
          >
            <Label className='typography-caption text-text-secondary' value={unit} position={{ x: 57, y: 10 }} />
          </YAxis>
          {showTooltip && (
            <Tooltip
              cursor={{ stroke: colorPalette.divider }}
              position={{ y: 0 }}
              allowEscapeViewBox={{ x: true }}
              animationDuration={150}
              content={(content) => <CustomTooltip {...content} unit={unit as UnitEnum} />}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
      <CustomLegendBar />
      {children}
    </div>
  );
};

const CustomLegendBar = () => {
  const { t } = useTranslation();

  const legendBarItems: ChartLegendBarItem[] = [
    {
      label: t('global.analysis.waterHoldingCapacity'),
      color: chartColors.whc.fill,
    },
  ];

  return <ChartLegendBar items={legendBarItems} data-testid='water-holding-capacity-chart-legend' />;
};

const CustomTooltip = ({ active, payload, label, unit }: TooltipProps<ValueType, NameType> & { unit: UnitEnum }) => {
  const { t } = useTranslation();
  const whcValue = payload?.find((data) => data.dataKey === 'whc.value');
  const whcDisplay =
    whcValue?.value != null
      ? `${getDisplayNumber(whcValue?.value as string, window.navigator.language)} ${unit}`
      : t('global.analysis.noData');

  if (!active) return null;
  return (
    <ChartTooltip>
      <ChartTooltipTextPrimary>{`${t('global.analysis.waterHoldingCapacity')}: ${whcDisplay}`}</ChartTooltipTextPrimary>
      <ChartTooltipTextSecondary>{printMonthYear(label)}</ChartTooltipTextSecondary>
    </ChartTooltip>
  );
};
