/* eslint-disable camelcase */
/* eslint-disable security/detect-object-injection */
import { valueToTonne } from '@/utils/formatting';
import { DateInput } from '@/utils/formatting/date';

import { ChartData, GraphFact } from './types';

// extending recharts api to set the axis limits to: eg: min=80% of the data min value, max=120% of the data max value
export const minMaxAxisDomain =
  (minMultiplier: number, maxMultiplier: number) =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  ([dataMin, dataMax]: [number, number], allowDataOverflow?: boolean): [number, number] =>
    [Math.floor(dataMin * minMultiplier), Math.ceil(dataMax * maxMultiplier)];

export const oneYearInMilliseconds = 1 * 365 * 24 * 60 * 60 * 1000;

export const getYearDomainForNumOfYearsFromToday =
  (yearsBefore = 0, yearsAfter = 0) =>
  ([minYear, maxYear]: [number, number]): [number, number] =>
    [
      Math.min(minYear, new Date().getFullYear() - yearsBefore),
      Math.max(maxYear, new Date().getFullYear() + yearsAfter),
    ] satisfies [number, number];

/**  Science spec
 * For historic graphs -
 * - 1 tick per year, evenly spaced
 * - min = min(2018 OR min from the timeseries)
 * - max = max(currentYear OR max from the timeseries)
 * - mm/yyyy
 */
export const getXAxisDomainForHistoricGraph = (minDate: number, maxDate: number) => {
  const minYear = new Date(minDate).getFullYear();
  const maxYear = new Date(maxDate).getFullYear();

  const yearRange = getYearDomainForNumOfYearsFromToday(0, 0)([Math.min(2018, minYear), maxYear]);
  const startYear = yearRange[0];
  const endYear = yearRange[1];

  const ticksRange = Array(endYear - startYear + 1).keys();
  return Array.from(ticksRange).map((x) => new Date(`12-31-${startYear + x}`).getTime());
};

/**  Science spec
 * For potential graphs -
 * - 1 tick per year, evenly spaced
 * - min = min(currentYear - 1 OR min from the timeseries)
 * - max = max(currentYear + 9 OR max from the timeseries)
 * - yyyy format
 */
export const getXAxisDomainForPotentialGraph = (minDate: number, maxDate: number) => {
  const minYear = new Date(minDate).getFullYear();
  const maxYear = new Date(maxDate).getFullYear();

  const yearRange = getYearDomainForNumOfYearsFromToday(1, 9)([minYear, maxYear]);
  const startYear = yearRange[0];
  const endYear = yearRange[1];

  const ticksRange = Array(endYear - startYear + 1).keys();
  return Array.from(ticksRange).map((x) => new Date(`12-31-${startYear + x}`).getTime());
};

export const kgToTonneFormatter = (fact: (Omit<GraphFact, 'value'> & { value?: number | null }) | undefined) => {
  if (!fact) {
    return null;
  }
  const { value, unit } = fact;
  return valueToTonne(value, unit);
};

export const getChartDataFromGraphFact = (
  data: GraphFact[],
  factNames: Record<string, string>,
  formatter?: (fact: Omit<GraphFact, 'value'> & { value?: number | null }) => number | null | undefined,
) => {
  return data.reduce((acc, curr) => {
    const { date, name, value, upper_confidence_limit, lower_confidence_limit } = curr;

    const dataPoint = {
      value: formatter?.(curr) ?? value,
      confidence_interval: [
        formatter?.({ ...curr, value: lower_confidence_limit }) ?? lower_confidence_limit,
        formatter?.({ ...curr, value: upper_confidence_limit }) ?? upper_confidence_limit,
      ],
    };

    const dataPointFactName = factNames[name] ?? '';

    /** Since we receive time series that may not overlap on all timestamps
     *  in such cases, we default to null (not 0, since 0 is a valid value) for that particular date
     *  The graphs will then connect these null values along the other non-null values to create a continuous graph
     *  If these points are not handled, the graph breaks since we need valid Y values along the shared X axis
     */
    const fact = { [dataPointFactName]: dataPoint ?? null };
    const otherFacts = Object.fromEntries(
      Object.keys(factNames)
        .filter((factName: string) => factName !== name)
        .map((factName) =>
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          factNames[factName] ? [factNames[factName], acc[date]?.[factNames[factName]!] ?? null] : [],
        ),
    );
    acc[date] = {
      ...fact,
      ...otherFacts,
    };

    return acc;
  }, {} as ChartData);
};

export const sortChartData = (data: ChartData, dateFormatter?: (date: DateInput) => DateInput) => {
  return (
    Object.entries(data)
      // @ts-ignore typescript does not allow arithmetic operations between date objects
      .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
      .map(([date, currentData]) => ({
        label: dateFormatter?.(date) ?? date,
        ...currentData,
      }))
  );
};
