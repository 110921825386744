import { useTranslation } from 'react-i18next';

import { Explainer, ExplainerTrigger } from './Explainer';

export const EcologicalIntegrityIndicatorsExplainer = () => {
  const { t } = useTranslation();

  return (
    <Explainer>
      <ExplainerTrigger data-testid='ecological-integrity-indicators-explainer' buttonVariant='outline'>
        {t('global.measurementExplainer.buttonLabel')}
      </ExplainerTrigger>
    </Explainer>
  );
};
