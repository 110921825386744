import { Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { ChartHeading } from '@/components/ChartsV1_2/components/ChartHeading';
import { GraphFact } from '@/components/ChartsV1_2/types';
import { WaterHistoricalChart } from '@/components/ChartsV1_2/WaterHistorical';
import { useNCCardContext } from '@/components/NCCardV1_2/NCCard';
import { usePlot } from '@/pages/shared/hooks/usePlot';
import { usePlotReportForPlot } from '@/pages/shared/hooks/usePlotReportForPlot';

import { NoPlotData } from '../NoPlotData';

export const WaterHistoricalGraphTile = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();
  const plot = usePlot().data;

  const { getFact } = usePlotReportForPlot({ plotId: plot.id });
  const historicWaterAndSoilMoisture = getFact<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.r1_historic_water_and_soil_moisture_graph
      : R1FactType.r1_historic_water_and_soil_moisture_per_ha_graph,
  );

  const chartData = historicWaterAndSoilMoisture?.value;
  const noChartData = !chartData?.length;

  if (noChartData) {
    return <NoPlotData />;
  }

  return (
    <Stack
      spacing={6}
      className='items-center justify-center p-6 lg:items-start lg:p-8 lg:pl-2'
      data-testid='water-historical-graph-tile'
    >
      <ChartHeading
        // TODO: MVP-3284 Update copy for historicalGraphHeadline
        heading={t('shared.ncaDetail.details.water.labels.historicalGraphHeadline')}
        popover={{
          title: t('shared.ncaDetail.details.water.explainers.historicalData.title'),
          body: t('shared.ncaDetail.details.water.explainers.historicalData.body'),
        }}
      />
      <WaterHistoricalChart
        analysisType={analysisType}
        data={chartData}
        showTooltip={true}
        className='relative'
        data-testid='water-historical-chart'
      />
    </Stack>
  );
};
