import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VerticalCoordinatesGenerator } from 'recharts-overriden/types/cartesian/CartesianGrid';

import { UnitEnum } from '@/api/rest/resources/types/units';
import { formatUnit } from '@/utils/formatting';
import { printMonthYear } from '@/utils/formatting/date';

import {
  CartesianGrid,
  Chart,
  ChartConfig,
  Line,
  LineChart,
  useTimeseriesXAxisProps,
} from './conservation/components/Chart';
import { useFact } from './conservation/hooks/useFact';

export const IndicatorSpeciesBreakdownChart = () => {
  const { t } = useTranslation();

  const chartConfig = {
    large_carnivore_terrestrial: {
      color: '#CA543C',
      label: t('shared.species.large_carnivore_terrestrial'),
    },
    large_carnivore_freshwater: {
      color: '#769DC7',
      label: t('shared.species.large_carnivore_freshwater'),
    },
    migratory: {
      color: '#B18B65',
      label: t('shared.species.migratory'),
    },
    endemic: {
      color: '#955454',
      label: t('shared.species.endemic'),
    },
    large_herbivore_terrestrial: {
      color: '#728A42',
      label: t('shared.species.large_herbivore_terrestrial'),
    },
    large_herbivore_freshwater: {
      color: '#5CB689',
      label: t('shared.species.large_herbivore_freshwater'),
    },
    ecosystem_engineer_keystone: {
      color: '#8D5495',
      label: t('shared.species.ecosystem_engineer_keystone'),
    },
    vulnerable_sensitive_sentinel_terrestrial: {
      color: '#D2A034',
      label: t('shared.species.vulnerable_sensitive_sentinel_terrestrial'),
    },
    vulnerable_sensitive_sentinel_freshwater: {
      color: '#6FADC8',
      label: t('shared.species.vulnerable_sensitive_sentinel_freshwater'),
    },
  } satisfies ChartConfig;

  type AttributeName = keyof typeof chartConfig;
  type ChartDataItem = {
    name: AttributeName;
    date: string;
    value: number;
    unit: UnitEnum;
  };

  const historic5YearGraph = useFact<ChartDataItem[]>('r1_conservation_species_pct_historic_graph');

  type DateToAttributesMap = {
    [date: ChartDataItem['date']]: { [attribute in ChartDataItem['name']]?: number };
  };

  const dateToAttributesMap = historic5YearGraph.value.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.date]: {
        ...acc[curr.date],
        [curr.name]: curr.value,
      },
    }),
    {} as DateToAttributesMap,
  );

  const chartData = Object.entries(dateToAttributesMap).map(([date, value]) => ({
    date: new Date(date),
    ...value,
  }));

  const xAxisProps = useTimeseriesXAxisProps(chartData, 'date');

  const [highlightedAttribute, setHighlightedAttribute] = useState<AttributeName | null>(null);

  return (
    <Chart.Container config={chartConfig} data={chartData} height={500}>
      <LineChart data={chartData}>
        <Chart.XAxis {...xAxisProps} axisLine={false} />
        <Chart.YAxis domain={[0, 100]} tickFormatter={(v) => `${v}${formatUnit(UnitEnum['%'])}`} axisLine={false} />
        <CartesianGrid
          horizontal={false}
          strokeDasharray='2 4'
          stroke='#000000'
          strokeOpacity={0.4}
          verticalCoordinatesGenerator={verticalCoordinatesGenerator(xAxisProps.ticks)}
        />
        <Chart.Tooltip
          cursor={{ strokeWidth: 2 }}
          content={(props) => (
            <Chart.TooltipContent
              {...props}
              labelFormatter={printMonthYear}
              formatter={(v) => `${v}${formatUnit(UnitEnum['%'])}`}
            />
          )}
        />
        {Object.keys(chartConfig).map((dataKey) => {
          // eslint-disable-next-line no-nested-ternary
          const opacity = highlightedAttribute === null ? 1 : dataKey === highlightedAttribute ? 1 : 0.25;

          return (
            <Line
              key={dataKey}
              dataKey={dataKey}
              stroke={`var(--color-${dataKey})`}
              dot={{
                fill: `var(--color-${dataKey})`,
                strokeWidth: 0,
                opacity,
                className: 'transition-opacity',
              }}
              activeDot={{
                fill: `var(--color-${dataKey})`,
                r: 5.5,
                opacity,
                className: 'transition-opacity',
              }}
              isAnimationActive={false}
              strokeOpacity={opacity}
              className='[&>path]:transition-[stroke-opacity]'
            />
          );
        })}
        <Chart.Legend
          content={(props) => (
            <Chart.LegendContent
              {...props}
              onAttributeHover={(dataKey) => setHighlightedAttribute(dataKey as AttributeName)}
            />
          )}
        />
      </LineChart>
    </Chart.Container>
  );
};

const verticalCoordinatesGenerator = (ticks: unknown[]) => (props: Parameters<VerticalCoordinatesGenerator>[0]) => {
  const ticksCount = ticks.length;

  const gridSpacing = (props.offset.width as number) / (ticksCount - 1);

  const offset = props.xAxis.x as number;

  return new Array(ticksCount).fill(null).map((_, index) => gridSpacing * index + offset);
};
