import { useTranslation } from 'react-i18next';
import { Stack } from 'tw-component-library';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { WaterHistoricalChart } from '@/components/ChartsV1_2';
import { ChartHeading } from '@/components/ChartsV1_2/components/ChartHeading';
import { GraphFact } from '@/components/ChartsV1_2/types';
import { useNCCardContext } from '@/components/NCCardV1_2/NCCard';
import { useNCData } from '@/pages/shared/hooks/useNCData';

import { NoProjectData } from '../../../../components/NoProjectData';

export const WaterCardGraphTile = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();

  const historicWaterAndSoilMoisture = useNCData<GraphFact[]>(
    analysisType === 'total'
      ? R1FactType.r1_historic_water_and_soil_moisture_graph
      : R1FactType.r1_historic_water_and_soil_moisture_per_ha_graph,
  );

  const chartData = historicWaterAndSoilMoisture?.value;
  const noChartData = !chartData?.length;

  if (noChartData) {
    return <NoProjectData />;
  }
  return (
    <Stack center data-testid='water-historic-graph-tile'>
      <Stack spacing={2.5} className='w-full items-center justify-center p-6 lg:items-start lg:p-8 lg:pl-2'>
        <ChartHeading
          heading={t('shared.ncaDetail.details.water.labels.historicalWHCGraphHeadline')}
          popover={{
            title: t('shared.ncaDetail.details.water.explainers.historicalData.title'),
            body: t('shared.ncaDetail.details.water.explainers.historicalData.body'),
          }}
        />
        <WaterHistoricalChart
          analysisType={analysisType}
          data={chartData}
          showTooltip={true}
          className='relative'
          data-testid='water-historical-chart'
        />
      </Stack>
    </Stack>
  );
};
